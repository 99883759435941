import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Event } from 'src/app/models/event';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Sentence } from 'src/app/models/sentence';
import { EventService } from 'src/app/services/api/event.service';
import { MessageService } from 'src/app/services/message.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TranscripService } from 'src/app/services/transcript.service';
import { UserService } from 'src/app/services/api/user.service';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeDialogComponent } from '../../dialogs/qrcode.dialog.component';
import { QrCodeData } from 'src/app/components/qrcode/qrCodeData';
import { BackendMessage, BackendMessageType } from 'src/app/models/backendMessage';
import { Location } from '@angular/common';
import { LanguageService } from 'src/app/services/api/language.service';
import { Language } from 'src/app/models/language';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { ConnectionState } from 'src/app/helpers/connectionState';
import { RecorderService } from 'src/app/services/recorder.service';
import * as copy from 'copy-to-clipboard';
import { LoggerService } from 'src/app/services/logger.service';
import { RecorderState, RecordingStatus } from 'src/app/models/recorderState';
import { TranscriptDownloadDialogComponent } from 'src/app/dialogs/transcript-download.dialog.component';
import { TranscriptDownloadRequest } from 'src/app/models/transcriptDownloadRequest';
import launchContext from '../../models/launchContext';

@Component({
  selector: 'app-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.scss']
})
export class RecorderComponent implements OnInit, OnDestroy {
  // Grab scrollable div from HTML to scroll it down after new sentences arrive
  @ViewChild('scrollframe', { static: false }) private scrollFrame: ElementRef;
  @ViewChild('waveAnimation', { static: false }) private waveAnimationElement: ElementRef<HTMLCanvasElement>;
  @ViewChildren('sentences') sentenceElements: QueryList<any>;

  isLoading = false;
  isResultsPanelOpen = false;
  isDebug = false;
  event = new Event();
  sentences = [];
  textDirection = 'ltr';
  listenerUrl: string;
  state: RecorderState;
  isAudioMonitorOn = false;
  showBackButton = false;
  showDownloadTranscript = true;
  showBookNotUsedWarning = false;

  // Language
  allLanguages = [];
  translationLanguages = [];
  currentLanguage: Language = new Language();

  private hasNoCorrectorWaningBeenShown: boolean;

  // Manage all subscriptions
  private subscriptionManager = new SubscriptionManager();

  // Connection
  connectionState: ConnectionState = ConnectionState.Disconnected;
  isConnected = false;
  isConnecting = false;
  isReconnecting = false;
  isDisconnected = true;

  constructor(
    public globals: GlobalsService,
    private recorderService: RecorderService,
    private eventService: EventService,
    private messageService: MessageService,
    private transcriptService: TranscripService,
    private userService: UserService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private logger: LoggerService
  ) {
    this.isDebug = environment.debugMode;
    this.showBackButton = launchContext.isTeamsMode;
    this.showDownloadTranscript = !launchContext.isTeamsMode;
  }

  async ngOnInit() {
    // Manage subscriptions
    this.subscriptionManager.add(
      this.globals.getIsLoading().subscribe(value => (this.isLoading = value)),
      this.recorderService.messageReceived.subscribe(value => this.onMessageReceived(value)),
      this.recorderService.behaviorSentences.subscribe(sentences => this.onSentencesChanged(sentences)),
      this.recorderService.languageChangedByCorrector.subscribe(value => this.onLanguageChangedByCorrector(value)),
      this.recorderService.sessionDurationInSeconds.subscribe(value => this.onSessionDurationChanged(value)),
      this.recorderService.connectionState.subscribe(state => this.onConnectionStateChanged(state)),
      this.recorderService.state.subscribe(async value => await this.onStateChanged(value)),
      this.recorderService.isAudioMonitorOn.subscribe(value => (this.isAudioMonitorOn = value))
    );

    try {
      this.globals.setIsLoading(true);

      // Get event, and available languages
      const eventId = this.route.snapshot.paramMap.get('id');
      this.event = await this.eventService.getEventAsync(eventId);
      this.allLanguages = await this.languageService.getRecordingLanguagesAsync();
      this.translationLanguages = await this.languageService.getTranslationLanguagesAsync();
      this.currentLanguage = this.allLanguages.find(x => x.code === this.event.language);
      this.listenerUrl = this.eventService.buildListenerUrlFor(this.event);

      // Connect to recording service and set language
      await this.recorderService.connectAsync(this.event, this.waveAnimationElement);
      await this.recorderService.setLanguageAsync(this.currentLanguage);
    } catch (error) {
      this.messageService.showReloadToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }

    this.subscriptionManager.add(this.sentenceElements.changes.subscribe(_ => this.scrollToBottom()));
  }

  async ngOnDestroy() {
    await this.recorderService.stopAsync();
    await this.recorderService.closeAsync();

    // Unsubscribe from all subscriptions
    this.subscriptionManager.unsubscribeAll();
  }

  private onSentencesChanged(sentences: Sentence[]) {
    this.sentences = sentences;
    const lastSentence = sentences[sentences.length - 1];

    // Set text direction based on the last sentenc that came in
    if (lastSentence) {
      this.textDirection = lastSentence.textDirection;
    }
  }

  async start() {
    this.hasNoCorrectorWaningBeenShown = false;

    // Refresh user to get his latest booked and used recording time
    try {
      this.globals.setIsLoading(true);

      if (!environment.disableBilling) {
        const user = await this.userService.getCurrentUserAsync();
        // Check, if user has booked enough minutes
        const minutesLeft = user.bookedMinutes - user.usedSeconds / 60;

        if (minutesLeft <= 0 || user.bookedMinutes <= 0) {
          await this.showBookMinutesDialog();
          return;
        }

        // Warn, if user has less than 1h left
        if (minutesLeft < 60) {
          if (!(await this.showTimeWarningDialog(Math.round(minutesLeft * 100) / 100))) {
            return;
          }
        }
      }
    } catch (error) {
      this.logger.logError(error);
      this.messageService.showReloadToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }

    // Start the recording
    try {
      this.globals.setIsLoading(true);
      await this.recorderService.startAsync();
    } catch (error) {
      console.log('An error occurred:');
      console.log(error);
      this.logger.logError(error);
      this.messageService.showToast('Could not start recording');
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async stopAsync(): Promise<void> {
    try {
      this.globals.setIsLoading(true);
      await this.recorderService.stopAsync();
    } catch (error) {
      this.logger.logError(error);
      this.messageService.showToast('Could not stop recording');
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async onSelectedLanguageChanged(languages: Language[]) {
    if (languages.length > 0) {
      this.globals.setIsLoading(true);
      this.currentLanguage = languages[0];
      await this.recorderService.setLanguageAsync(languages[0]);
      this.globals.setIsLoading(false);
    }
  }

  private async onLanguageChangedByCorrector(language: Language) {
    if (this.currentLanguage !== language) {
      this.currentLanguage = this.allLanguages.find(x => x.code === language.code);
      this.messageService.showToast('A corrector has changed the language to ' + language.name);
    }
  }

  private async onStateChanged(state: RecorderState): Promise<void> {
    this.state = state;

    const shouldUseVocabularyBook = this.hasVocabularyBookForLanguage(this.event, this.currentLanguage.code);
    this.showBookNotUsedWarning =
      this.state.recordingStatus === RecordingStatus.Recording &&
      shouldUseVocabularyBook &&
      !state.isUsingVocabularyBook;
  }

  copyListenerToClipboard() {
    if (copy(this.eventService.buildListenerUrlFor(this.event), { format: 'text/plain' })) {
      this.messageService.showToast('Copied listener url to clipboard');
    }
  }

  copyCorrectorToClipboard() {
    if (copy(this.eventService.buildCorrectorUrlFor(this.event), { format: 'text/plain' })) {
      this.messageService.showToast('Copied corrector url to clipboard');
    }
  }

  openListenerWindow() {
    window.open(this.eventService.buildListenerUrlFor(this.event), '_blank', 'height=500,width=700');
  }

  openListenerApp() {
    const url = this.eventService.buildListenerUrlFor(this.event);
    window.open('starteve://listener?url=' + url);
  }

  private scrollToBottom() {
    this.scrollFrame.nativeElement.scrollTop = this.scrollFrame.nativeElement.scrollHeight;
  }

  openCorrector() {
    window.open(this.eventService.buildCorrectorUrlFor(this.event), '_blank');
  }

  navigateBack() {
    this.router.navigate(['/']);
  }

  openQrCodeWindow() {
    this.dialog.open(QrCodeDialogComponent, {
      data: new QrCodeData(
        this.eventService.buildListenerUrlFor(this.event),
        this.eventService.buildQrCodeUrlFor(this.event)
      )
    });
  }

  async clearListenersAsync() {
    await this.recorderService.clearListenersAsync();
  }

  async openDownloadTranscriptDialogAsync(): Promise<void> {
    const dialog = this.dialog.open(TranscriptDownloadDialogComponent, {
      maxWidth: 900,
      data: {
        event: this.event,
        languages: this.translationLanguages
      }
    });

    dialog.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }

      const loadingDialog = this.messageService.showLoadingDialog(
        'Preparing transcript',
        'This can take up to 60 seconds.',
        true
      );
      try {
        const request = result as TranscriptDownloadRequest;
        await this.transcriptService.downloadAsync(this.event, request);
      } catch (error) {
        this.messageService.showToast(error.message);
      } finally {
        loadingDialog.close();
      }
    });
  }

  toggleAudioMonitor(): void {
    this.recorderService.toggleAudioMonitor();
  }

  async deleteEvent() {
    const result = await this.messageService.showActionDialogAsync(
      'Are you sure?',
      'Do you really want to delete this event? You can restore it from the trash at any time.',
      'Move to trash',
      'Cancel'
    );

    if (result === true) {
      try {
        this.globals.setIsLoading(true);
        const event = await this.eventService.deleteEventAsync(this.event, true);
        this.location.back();
      } catch (error) {
        this.messageService.showToast(error.message);
      } finally {
        this.globals.setIsLoading(false);
      }
    }
  }

  showHideResultsPanel() {
    this.isResultsPanelOpen = !this.isResultsPanelOpen;
  }

  async addDemoSentence(): Promise<void> {
    await this.recorderService.addDemoSentenceAsync(false);
  }

  async addLongDemoSentence(): Promise<void> {
    await this.recorderService.addDemoSentenceAsync(true);
  }

  async addLongTestSentence(addDelay: boolean): Promise<void> {
    await this.recorderService.addLongTestSentenceAsync(addDelay);
  }

  private async onMessageReceived(message: BackendMessage) {
    console.log('Backend message received.', message);

    switch (message.type) {
      default:
        break;
      case BackendMessageType.BookedTimeExceeded:
        this.showTimeExceededWarning();
        break;
      case BackendMessageType.NoCorrectorConnected:
        this.showNoCorroctorConnectedWarning();
        break;
      case BackendMessageType.MultipleRecordersConnected:
        this.showMultipleRecordersConnectedWarning();
        break;
      case BackendMessageType.RecordingLanguageChanged:
        this.messageService.showToast('The language has been changed.');
        break;
      case BackendMessageType.CorrectorNotApproving:
        this.messageService.showToast(
          message.details + ' sentence(s) are still waiting for an approval by the corrector.'
        );
        break;
      case BackendMessageType.InternalError:
        this.showBackendErrorWarning();
        break;
      case BackendMessageType.NotAllowed:
        this.messageService.showToast('You are not allowed to do this.');
        break;
    }
  }

  private async showMultipleRecordersConnectedWarning() {
    // Show full dialog only first time
    await this.messageService.showDialogAsync(
      'Another recorder is already connected',
      'For this event, there already is a recorder connected. ' +
        'Please stop the other recording, before you can start a new one.',
      'Ok'
    );
  }

  private async showBackendErrorWarning() {
    // Show full dialog only first time
    await this.messageService.showDialogAsync(
      // tslint:disable-next-line:quotemark
      "It's not you, it's us!",
      'Whoops, something bad happened on our servers and we had to stop the recording. ' +
        'Sorry for the inconvenience. If this happens again, please contact the support.',
      'Ok'
    );
  }

  private async showBookMinutesDialog() {
    const result = await this.messageService.showActionDialogAsync(
      'Not enough recording time booked',
      'You do not have enough recording time left to start a new recording.\n' +
        'You can book recording time in our shop.',
      launchContext.isTeamsMode ? 'Ok' : 'Visit the shop',
      'Cancel'
    );

    if (launchContext.isTeamsMode) {
      return;
    }

    if (result) {
      window.open('https://starteve.ai/#pricing', '_blank');
    }
  }

  private async showTimeWarningDialog(minutesLeft: number): Promise<boolean> {
    const pressedButtonId = await this.messageService.showMultiActionDialogAsync(
      'Less than 60 minutes left',
      'You only have less than ' +
        Math.ceil(minutesLeft) +
        ' minute(s) left for this recording. Please consider buying more recording time.' +
        'to avoid unwanted interruptions.',
      ['Continue', 'Buy more', 'Cancel']
    );

    if (pressedButtonId === 1) {
      window.open('https://starteve.ai/#pricing', '_blank');
    }

    return pressedButtonId === 0;
  }

  private async showVocabularyBookNotReadyDialogAsync(): Promise<boolean> {
    const result = await this.messageService.showMultiActionDialogAsync(
      'Vocabulary book not ready',
      'You have defined a custom vocabulary book for this language, but the training for ' +
        'this book has not been completed yet.\nYou can proceed without using the custom ' +
        'vocabulary or wait until the training is completed.',
      ['Continue', 'Show vocabulary book', 'Cancel']
    );

    if (result === 1) {
      this.router.navigate(['/book/' + this.event.vocabularyBooks[this.event.language]]);
    }

    return result === 0;
  }

  private async showTimeExceededWarning() {
    const result = await this.messageService.showActionDialogAsync(
      'Not enough recording time booked',
      'You do not have enough recording time left.\nYou can book recording time in our shop.',
      'Visit the shop',
      'Cancel'
    );

    if (result) {
      window.open('https://starteve.ai/#pricing', '_blank');
    }
  }

  private async showNoCorroctorConnectedWarning() {
    // Show full dialog only first time
    if (!this.hasNoCorrectorWaningBeenShown) {
      this.hasNoCorrectorWaningBeenShown = true;
      await this.messageService.showDialogAsync(
        'No corrector connected',
        'You chose to use a corrector for this event, but no corrector seems to be connected. ' +
          'Please ensure to connect with a corrector or disable the corrector in the event ' +
          'settings. Otherwise your listeners will not see any results.',
        'Ok'
      );
    } else {
      this.messageService.showToast('No corrector connected, so results will not be shown to listeners.');
    }
  }

  private onSessionDurationChanged(value: number) {
    this.event.duration++;
  }

  private onConnectionStateChanged(state: ConnectionState): void {
    this.isConnected = state === ConnectionState.Connected;
    this.isReconnecting = state === ConnectionState.Reconnecting;
    this.isDisconnected = state === ConnectionState.Disconnected;
    this.isConnecting = state === ConnectionState.Connecting || state === ConnectionState.Reconnecting;

    if (state === ConnectionState.Reconnecting) {
      this.messageService.showToast('Connection lost. Trying to reconnect...');
    }

    if (this.connectionState === ConnectionState.Reconnecting && state === ConnectionState.Disconnected) {
      this.messageService.showToast('Reconnecting to the server failed.');
    }

    if (this.connectionState === ConnectionState.Reconnecting && state === ConnectionState.Connected) {
      this.messageService.showToast('Successfully reconnected to the server.');
    }

    this.connectionState = state;
  }

  private hasVocabularyBookForLanguage(event: Event, language: string): boolean {
    if (event.vocabularyBooks === undefined) {
      return false;
    }

    return event.vocabularyBooks[language] !== undefined;
  }
}
