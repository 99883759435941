export default class Auth0Config {
  clientId: string;
  domain: string;
  loginRedirectUri: string;
  logoutRedirectUrl: string;
  responseType: string;
  audience: string;
  scope: string;

  constructor(
    clientId: string,
    domain: string,
    loginRedirectUri: string,
    logoutRedirectUrl: string,
    responseType: string,
    audience: string,
    scope: string
  ) {
    this.clientId = clientId;
    this.domain = domain;
    this.loginRedirectUri = loginRedirectUri;
    this.logoutRedirectUrl = logoutRedirectUrl;
    this.responseType = responseType;
    this.audience = audience;
    this.scope = scope;
  }
}
