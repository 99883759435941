
export class QrCodeData {
  qrData: string;
  qrUrl: string;
  qrCodeSize: number;

  constructor(qrData: string, qrUrl?: string, size?: number) {
    this.qrData = qrData;
    this.qrUrl = qrUrl;
    this.qrCodeSize = size || this.getSize();
  }

  private getSize() {
    const PADDING = 0.7; // CSS property viewport width is set to 80
    const windowSize = Math.min(window.innerWidth, window.innerHeight);
    const size = windowSize * PADDING;
    return size;
  }
}
