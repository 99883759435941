import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { User } from '../../models/user';
import { environment } from 'src/environments/environment';
import { Event } from '../../models/event';
import { BaseApiService } from './base-api.service';
import { LocalCache } from '../../helpers/localCache';
import { VocabularyBook } from 'src/app/models/vocabularyBook';
import { SpeechModel } from 'src/app/models/speechModel';
import { BookingLog } from 'src/app/models/bookingLog';
import { Recording } from 'src/app/models/recording';
import { Billing } from 'src/app/models/billing';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseApiService {
  private cachedUsers: LocalCache<User>;
  private cachedEvents: LocalCache<Event>;
  private cachedVocabularyBooks: LocalCache<VocabularyBook>;
  private cachedSpeechModels: LocalCache<SpeechModel>;
  private cachedBookingLogs: LocalCache<BookingLog>;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super(authService);
    this.cachedUsers = new LocalCache<User>();
    this.cachedEvents = new LocalCache<Event>();
    this.cachedVocabularyBooks = new LocalCache<VocabularyBook>();
    this.cachedSpeechModels = new LocalCache<SpeechModel>();
    this.cachedBookingLogs = new LocalCache<BookingLog>();
  }

  async getAllUsersAsync(): Promise<User[]> {
    // Try to return from local cache
    if (this.cachedUsers.hasItems()) {
      return this.cachedUsers.getItems();
    }

    const response = await this.httpClient
      .get<User[]>(environment.apiServiceUrl + '/api/admin/user', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    // Update cache
    this.cachedUsers.replace(response);

    return response;
  }

  async getUserAsync(id: string): Promise<User> {
    const response = await this.httpClient
      .get<User>(environment.apiServiceUrl + '/api/admin/user/' + id, {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }

  async getAllEventsAsync(): Promise<Event[]> {
    // Try to return from local cache
    if (this.cachedEvents.hasItems()) {
      return this.cachedEvents.getItems();
    }

    const response = await this.httpClient
      .get<Event[]>(environment.apiServiceUrl + '/api/admin/event', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    // Update cache
    this.cachedEvents.replace(response);

    return response;
  }

  async getEventsForUserAsync(userId: string): Promise<Event[]> {
    const response = await this.httpClient
      .get<Event[]>(environment.apiServiceUrl + '/api/admin/user/' + userId + '/event', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }

  async getBillingsForUserAsync(userId: string): Promise<Billing[]> {
    const response = await this.httpClient
      .get<Billing[]>(environment.apiServiceUrl + '/api/admin/user/' + userId + '/billings', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }

  async getRecordingsByEventIdAsync(eventId: string): Promise<Recording[]> {
    const response = await this.httpClient
      .get<Recording[]>(environment.apiServiceUrl + '/api/admin/event/' + eventId + '/recordings', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }

  async updateBookedMinutesForUserAsync(id: string, bookedMinutes: number): Promise<User> {
    const response = await this.httpClient
      .put<User>(
        environment.apiServiceUrl + '/api/admin/user/' + id + '/booked-minutes',
        { bookedMinutes },
        { headers: await this.getHeadersAsync() }
      )
      .toPromise();

    // Update user in local cache
    this.cachedUsers.updateItem(x => x.id === response.id, response);

    return response;
  }

  async getEventByIdAsync(eventId: string): Promise<Event> {
    const response = await this.httpClient
      .get<Event>(environment.apiServiceUrl + '/api/admin/event/' + eventId, {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }

  async getAllVocabularyBooksAsync(): Promise<VocabularyBook[]> {
    // Try to return from local cache
    if (this.cachedVocabularyBooks.hasItems()) {
      return this.cachedVocabularyBooks.getItems();
    }

    const response = await this.httpClient
      .get<VocabularyBook[]>(environment.apiServiceUrl + '/api/admin/vocabularybook', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    // Update cache
    this.cachedVocabularyBooks.replace(response);

    return response;
  }

  async getVocabularyBooksForUserAsync(userId: string): Promise<VocabularyBook[]> {
    const response = await this.httpClient
      .get<VocabularyBook[]>(environment.apiServiceUrl + '/api/admin/vocabularybook', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    // Update cache
    this.cachedVocabularyBooks.replace(response);

    return response;
  }

  async retrainVocabularyBookAsync(id: string): Promise<void> {
    const response = await this.httpClient
      .post<void>(environment.apiServiceUrl + '/api/admin/vocabularybook/' + id + '/retrain', null, {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }

  async getAllSpeechModelsAsync(): Promise<SpeechModel[]> {
    // Try to return from local cache
    if (this.cachedSpeechModels.hasItems()) {
      return this.cachedSpeechModels.getItems();
    }

    const response = await this.httpClient
      .get<SpeechModel[]>(environment.apiServiceUrl + '/api/admin/speechmodel', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    // Update cache
    this.cachedSpeechModels.replace(response);

    return response;
  }

  async getAllBookingLogsAsync(): Promise<BookingLog[]> {
    // Try to return from local cache
    if (this.cachedBookingLogs.hasItems()) {
      return this.cachedBookingLogs.getItems();
    }

    const response = await this.httpClient
      .get<BookingLog[]>(environment.apiServiceUrl + '/api/admin/bookinglog', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    // Update cache
    this.cachedBookingLogs.replace(response);

    return response;
  }

  async getBookingLogsForUserAsync(userId: string): Promise<BookingLog[]> {
    const response = await this.httpClient
      .get<BookingLog[]>(environment.apiServiceUrl + '/api/admin/user/' + userId + '/bookinglogs', {
        headers: await this.getHeadersAsync()
      })
      .toPromise();

    return response;
  }
}
