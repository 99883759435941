import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { DeleteEventComponent } from './components/delete-event/delete-event.component';
import { RecorderComponent } from './components/recorder/recorder.component';
import { ListenerComponent } from './components/listener/listener.component';
import { CorrectorComponent } from './components/corrector/corrector.component';
import { QrcodeComponent } from './components/qrcode/qrcode.component';
import { CallbackComponent } from './components/callback/callback.component';
import { AccountComponent } from './components/account/account.component';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminUserDetailsComponent } from './components/admin-userdetails/admin-userdetails.component';
import { AdminEventDetailsComponent } from './components/admin-eventdetails/admin-eventdetails.component';
import { AboutComponent } from './components/about/about.component';
import { CreateVocabularyBookComponent } from './components/create-vocabulary-book/create-vocabulary-book.component';
import { ConnectComponent } from './components/connect/connect.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'about', component: AboutComponent },

  { path: 'account', component: AccountComponent },
  { path: 'login', component: LoginComponent },

  { path: 'event/new', component: CreateEventComponent },
  { path: 'event/:id', component: RecorderComponent },
  { path: 'event/:id/edit', component: CreateEventComponent },
  { path: 'trash', component: DeleteEventComponent },
  { path: 'trash/:id', component: DeleteEventComponent },

  { path: 'book/new', component: CreateVocabularyBookComponent },
  { path: 'book/:id', component: CreateVocabularyBookComponent },

  { path: 'listener/:url', component: ListenerComponent },
  { path: 'corrector/:url', component: CorrectorComponent },
  { path: 'moderator/:url', component: CorrectorComponent }, // Legacy. Corrector was called Moderator once.

  { path: 'qrcode/:url', component: QrcodeComponent },

  { path: 'admin', component: AdminComponent },
  { path: 'admin/user/:id', component: AdminUserDetailsComponent },
  { path: 'admin/event/:id', component: AdminEventDetailsComponent },

  { path: 'connect', component: ConnectComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
