import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { HomeComponent } from './components/home/home.component';
import { RecorderComponent } from './components/recorder/recorder.component';
import { ListenerComponent } from './components/listener/listener.component';
import { CorrectorComponent } from './components/corrector/corrector.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { CallbackComponent } from './components/callback/callback.component';
import { AccountComponent } from './components/account/account.component';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminUserDetailsComponent } from './components/admin-userdetails/admin-userdetails.component';
import { AdminEventDetailsComponent } from './components/admin-eventdetails/admin-eventdetails.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { AboutComponent } from './components/about/about.component';
import { LoadingDialogComponent } from './dialogs/loading.dialog.component';
import { QrCodeDialogComponent } from './dialogs/qrcode.dialog.component';
import { PasswordDialogComponent } from './dialogs/password.dialog.component';
import { QrcodeComponent } from './components/qrcode/qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DefaultDialogComponent } from './dialogs/default.dialog.component';
import { CreateVocabularyBookComponent } from './components/create-vocabulary-book/create-vocabulary-book.component';
import { DeleteEventComponent } from './components/delete-event/delete-event.component';
import { environment } from 'src/environments/environment';
import { MccColorPickerModule } from 'material-community-components';
import { DragAndDropContainerDirective } from './directives/drag-drop/drag-and-drop-container.directive';
import { DropZoneDirective } from './directives/drag-drop/drag-and-drop-drop-zone.directive';
import { BillingComponent } from './components/billing/billing.component';
import { MatPaginatorModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material';
import { StopwatchPipe } from './pipes/stopwatch.pipe';
import { HumanReadablePipe } from './pipes/human-readable.pipe';
import { ConnectComponent } from './components/connect/connect.component';
import { ControlDisabledDirective } from './directives/control-disabled/control-disabled.directive';
import { TranscriptDownloadDialogComponent } from './dialogs/transcript-download.dialog.component';
import { UrlParametersDialogComponent } from './dialogs/url-parameters.dialog.component';
import { CorrectorSentenceComponent } from './components/corrector-sentence/corrector-sentence.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormColorPickerComponent } from './components/form-color-picker/form-color-picker.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ZoomPluginDialogComponent } from './dialogs/zoom-plugin.dialog.component';
import { LangageSelectionDialogComponent } from './dialogs/language-selection.dialog.component';
import { NgPipesModule } from 'ngx-pipes';
import { HighlightHtmlPipe } from './pipes/highlight-html.pipe';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ContenteditableModule } from '@ng-stack/contenteditable';
import Auth0Config from './auth/auth0config';
import { BookedMinutesDialogComponent } from './dialogs/booked-minutes.dialog.component';
import { PublicClientApplication } from '@azure/msal-browser';
import AzureActiveDirectoryConfig from './auth/azureActiveDirectoryConfig';
import { BookingLogsComponent } from './components/booking-logs/booking-logs.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RecorderComponent,
    ListenerComponent,
    CorrectorComponent,
    SettingsComponent,
    CreateEventComponent,
    AccountComponent,
    CallbackComponent,
    LoginComponent,
    AdminComponent,
    AdminUserDetailsComponent,
    AdminEventDetailsComponent,
    LoadingDialogComponent,
    QrCodeDialogComponent,
    DefaultDialogComponent,
    PasswordDialogComponent,
    TranscriptDownloadDialogComponent,
    UrlParametersDialogComponent,
    AboutComponent,
    QrcodeComponent,
    CreateVocabularyBookComponent,
    DeleteEventComponent,
    DragAndDropContainerDirective,
    DropZoneDirective,
    BillingComponent,
    StopwatchPipe,
    HumanReadablePipe,
    ConnectComponent,
    CorrectorSentenceComponent,
    ControlDisabledDirective,
    FormColorPickerComponent,
    ZoomPluginDialogComponent,
    LangageSelectionDialogComponent,
    HighlightHtmlPipe,
    LanguageSelectorComponent,
    BookedMinutesDialogComponent,
    BookingLogsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HotkeyModule.forRoot({ cheatSheetCloseEsc: true }),
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    QRCodeModule,
    MccColorPickerModule.forRoot({ empty_color: 'transparent' }),
    MatPaginatorModule,
    ScrollingModule,
    NgxMatSelectSearchModule,
    NgPipesModule,
    ContenteditableModule
  ],
  entryComponents: [
    LoadingDialogComponent,
    DefaultDialogComponent,
    PasswordDialogComponent,
    QrCodeDialogComponent,
    TranscriptDownloadDialogComponent,
    UrlParametersDialogComponent,
    ZoomPluginDialogComponent,
    LangageSelectionDialogComponent,
    BookedMinutesDialogComponent
  ],
  providers: [
    DatePipe,
    // Configure AuthService
    {
      provide: 'auth0Config',
      useValue: new Auth0Config(
        environment.auth0ClientID,
        environment.auth0Domain,
        `${location.protocol}//${location.host}/callback`,
        `${location.protocol}//${location.host}/login`,
        'token id_token',
        environment.auth0Audience,
        'openid profile email read:current_user write:events read:events start:recording write:profile read:billings'
      )
    },
    {
      provide: 'aadConfig',
      useValue: new AzureActiveDirectoryConfig(
        environment.aadClientID,
        environment.aadAuthority,
        `${location.protocol}//${location.host}/callback`,
        environment.auth0Audience,
        'openid profile email api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/access_as_user api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/write:events api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/read:events api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/start:recording api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/write:profile api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/read:users api://app.starteve.ai/66c33609-a7ff-42dc-9c64-65bf86d34b59/read:billings'
      )
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
