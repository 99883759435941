import { ElementRef } from '@angular/core';
import autobind from 'autobind-decorator';
import { BehaviorSubject, Subject } from 'rxjs';

// HACK: We are currently fixing, compiling and manually adding wave.js here, until
// this issue is fixed: https://github.com/foobar404/Wave.js/issues/21
// or this PR is merged: https://github.com/foobar404/Wave.js/pull/14
import Wave from 'src/assets/fixed-wavejs-bundle.cjs.js';

export default class LocalAudioRecordingService {
  private audioContext?: AudioContext;
  private mediaSource?: MediaStreamAudioSourceNode;
  private filter?: BiquadFilterNode;
  private stream?: MediaStream;
  private scriptProcessorNode?: ScriptProcessorNode;
  private animationCanvas: ElementRef<HTMLCanvasElement>;
  private waveAnimation: any;

  chunkReceived = new Subject<ArrayBuffer>();
  isAudioMonitorOn = new BehaviorSubject<boolean>(false);
  isRecording = false;

  constructor(animationCanvas: ElementRef<HTMLCanvasElement>) {
    this.animationCanvas = animationCanvas;
  }

  async startAsync(): Promise<void> {
    // TODO: If this gets called multiple times, it creates multiple streams which can't be stopped.
    this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    if (!this.stream) {
      console.error('Could not get the audio stream!');
      return;
    }

    // To support Safari, AudioContext has to be declared like this.
    // Details: https://stackoverflow.com/questions/48757933/audiocontext-issue-on-safari
    this.audioContext = new (window['AudioContext'] || window['webkitAudioContext'])();
    this.mediaSource = this.audioContext.createMediaStreamSource(this.stream);
    this.isRecording = true;

    this.filter = this.audioContext.createBiquadFilter();
    this.filter.type = 'lowpass';
    this.filter.frequency.setValueAtTime(8000, this.audioContext.currentTime);

    this.scriptProcessorNode = this.audioContext.createScriptProcessor(4096, 1, 1);
    this.scriptProcessorNode.onaudioprocess = this.processAudio;

    this.mediaSource.connect(this.filter);

    this.filter.connect(this.scriptProcessorNode);
    this.scriptProcessorNode.connect(this.audioContext.destination);

    if (this.isAudioMonitorOn.value) {
      this.startMonitor();
    }

    // Start animation
    this.waveAnimation = new Wave();
    this.waveAnimation.fromStream(
      this.stream,
      this.animationCanvas.nativeElement.id,
      {
        type: 'shine',
        colors: ['#0374ca', 'transparent']
      },
      false
    );
  }

  async stopAsync(): Promise<void> {
    try {
      await this.audioContext.close();
    } catch (error) {
      console.warn('Local audio context can not be closed.', error);
    }

    if (this.waveAnimation) {
      this.waveAnimation.stopStream();
      this.waveAnimation = undefined;
      const canvas = this.animationCanvas.nativeElement;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    }

    try {
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
      }
    } catch (error) {
      console.warn('Local audio stream can not be stopped.', error);
    }

    this.isRecording = false;
  }

  toggleAudioMonitor(): void {
    this.isAudioMonitorOn.next(!this.isAudioMonitorOn.value);

    // Activate or deactivate the audio monitor if the recording is currently running.
    if (this.isRecording) {
      this.isAudioMonitorOn.value ? this.startMonitor() : this.stopMonitor();
    }
  }

  private startMonitor(): void {
    this.mediaSource.connect(this.audioContext.destination);
    this.isAudioMonitorOn.next(true);
  }

  private stopMonitor(): void {
    this.mediaSource.disconnect(this.audioContext.destination);
    this.isAudioMonitorOn.next(false);
  }

  @autobind
  private async processAudio(audioProcessingEvent: AudioProcessingEvent): Promise<void> {
    const inputBuffer = audioProcessingEvent.inputBuffer;

    const inSampleRate = inputBuffer.sampleRate;
    const outSampleRate = 16000;
    const inputData = inputBuffer.getChannelData(0);
    const output = this.downsampleArray(inSampleRate, outSampleRate, inputData);

    this.chunkReceived.next(output.buffer);
  }

  private downsampleArray(inRate: any, outRate: any, input: any): Int16Array {
    const ratio = inRate / outRate;
    const outLength = Math.round(input.length / ratio);
    const output = new Int16Array(outLength);

    let inIndex = 0;

    for (let outIndex = 0; outIndex < output.length; outIndex++) {
      const nextIndex = Math.round((outIndex + 1) * ratio);

      let sum = 0;
      let count = 0;

      for (; inIndex < nextIndex && inIndex < input.length; inIndex++) {
        sum += input[inIndex];
        count++;
      }

      //saturate output between -1 and 1
      const newFVal = Math.max(-1, Math.min(sum / count, 1));

      //multiply negative values by 2^15 and positive by 2^15 -1 (range of short)
      const newsval = newFVal < 0 ? newFVal * 0x8000 : newFVal * 0x7fff;

      output[outIndex] = Math.round(newsval);
    }

    return output;
  }
}
