export enum RecordingStatus {
  Starting = 'Starting',
  Recording = 'Recording',
  Stopping = 'Stopping',
  Stopped = 'Stopped'
}

export class RecorderState {
  isReady: boolean;
  isSwitchingLanguage: boolean;
  isFailed: boolean;
  isUsingVocabularyBook: boolean;
  recordingStatus: RecordingStatus;

  constructor() {
    this.isReady = false;
    this.isSwitchingLanguage = false;
    this.isFailed = false;
    this.isUsingVocabularyBook = false;
    this.recordingStatus = RecordingStatus.Stopped;
  }
}
