// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  debugMode: window['env']['debugMode'] === 'true',
  disableBilling: window['env']['disableBilling'] === 'true',

  version: window['env']['version'],
  build: window['env']['build'],
  name: window['env']['name'],

  apiServiceUrl: window['env']['apiServiceUri'],
  streamingServiceUrl: window['env']['streamingServiceUri'],

  auth0Enabled: window['env']['auth0Enabled'] === 'true',
  auth0ClientID: window['env']['auth0ClientId'],
  auth0Domain: window['env']['auth0Domain'],
  auth0Audience: window['env']['auth0Audience'],

  aadEnabled: window['env']['aadEnabled'] === 'true',
  aadClientID: window['env']['aadClientId'],
  aadAuthority: window['env']['aadAuthority'],

  enableFileUploads: window['env']['enableFileUploads'] === 'true',

  appInsightsInstrumentationKey: window['env']['appInsightsInstrumentationKey'],
  logLevel: window['env']['logLevel']
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
