import { Injectable } from '@angular/core';
import { VocabularyBook } from '../../models/vocabularyBook';
import { BaseApiService } from './base-api.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { VocabularyBookInUseError } from '../../errors/vocabulary-book-in-use-error';
import { LocalCache } from '../../helpers/localCache';

@Injectable({
  providedIn: 'root'
})
export class VocabularyBookService extends BaseApiService {
  private cachedBooks: LocalCache<VocabularyBook>;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super(authService);
    this.cachedBooks = new LocalCache<VocabularyBook>();
  }

  async getBooksAsync(showDeleted: boolean = false): Promise<VocabularyBook[]> {
    let books: VocabularyBook[];

    // Try to return from local cache
    if (this.cachedBooks.hasItems()) {
      books = this.cachedBooks.getItems();
    } else {
      try {
        const response = await this.httpClient
          .get<VocabularyBook[]>(environment.apiServiceUrl + '/api/vocabularybook', {
            headers: await this.getHeadersAsync()
          })
          .toPromise();

        // Update cache
        this.cachedBooks.replace(response);

        books = response;
      } catch (error) {
        throw new Error('An error occurred, while trying to load the list of vocabulary books.');
      }
    }

    if (!showDeleted) {
      return books.filter(book => !book.isDeleted);
    }
    return books;
  }

  async getBookAsync(id: string, ignoreCache = false): Promise<VocabularyBook> {
    // Try to return from local cache
    if (!ignoreCache) {
      const cachedBook = this.cachedBooks.getItem(x => x.id === id);
      if (cachedBook) {
        return cachedBook;
      }
    }

    try {
      const response = await this.httpClient
        .get<VocabularyBook>(environment.apiServiceUrl + '/api/vocabularybook/' + id, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Update local cache
      this.cachedBooks.updateItem(x => x.id === response.id, response);

      return response;
    } catch {
      throw new Error('An error occurred, while trying to load the vocabulary book.');
    }
  }

  async createBookAsync(book: VocabularyBook): Promise<VocabularyBook> {
    try {
      const response = await this.httpClient
        .post<VocabularyBook>(environment.apiServiceUrl + '/api/vocabularybook', book, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Add to local cache
      this.cachedBooks.addItem(response);

      return response;
    } catch (error) {
      throw new Error('An error occurred while trying to create a new vocabulary book.');
    }
  }

  async changeBookAsync(book: VocabularyBook): Promise<VocabularyBook> {
    try {
      const response = await this.httpClient
        .put<VocabularyBook>(environment.apiServiceUrl + '/api/vocabularybook/' + book.id, book, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Update local cache
      this.cachedBooks.updateItem(x => x.id === book.id, response);

      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to change vocabulary book.');
    }
  }

  async deleteBookAsync(book: VocabularyBook, softDelete = false): Promise<VocabularyBook> {
    try {
      if (softDelete) {
        book.isDeleted = true;
        const response = await this.httpClient
          .put<VocabularyBook>(environment.apiServiceUrl + '/api/vocabularybook/' + book.id, book, {
            headers: await this.getHeadersAsync()
          })
          .toPromise();

        // Update local cache
        this.cachedBooks.updateItem(x => x.id === book.id, response);

        return response;
      } else {
        const response = await this.httpClient
          .delete<VocabularyBook>(environment.apiServiceUrl + '/api/vocabularybook/' + book.id, {
            headers: await this.getHeadersAsync()
          })
          .toPromise();

        // Update local cache
        this.cachedBooks.deleteItem(x => x.id === book.id);

        return response;
      }
    } catch (error) {
      if (error.status === 409) {
        // Conflict
        throw new VocabularyBookInUseError(error.error);
      } else {
        throw new Error('An error occurred, while trying to delete vocabulary book.');
      }
    }
  }

  async startTrainingAsync(book: VocabularyBook): Promise<VocabularyBook> {
    try {
      const response = await this.httpClient
        .post<VocabularyBook>(
          environment.apiServiceUrl + '/api/vocabularybook/' + book.id + '/train',
          {},
          {
            headers: await this.getHeadersAsync()
          }
        )
        .toPromise();

      // Update local cache
      this.cachedBooks.updateItem(x => x.id === book.id, response);

      return response;
    } catch (error) {
      this.handleHttpError(error, 'An error occurred while trying to start the training. ' + error.message);
    }
  }
}
