import { hasDecimalPlaceBetween } from '../helpers/numberHelpers';

export class Sentence {
  public get isInsertedSentence(): boolean {
    return hasDecimalPlaceBetween(this.order, 3, 5);
  }

  constructor(
    public id: string,
    public language: string,
    public creation: string,
    public order: number,
    public text: string,
    public originalText: string,
    public translations: { [key: string]: string },
    public isComplete: boolean,
    public isApproved: boolean,
    public textDirection: string,
    public start?: Date,
    public end?: Date
  ) {}
}
