import IAuthProvider from './IAuthProvider';
import TokenResult from './tokenResult';
import AzureActiveDirectoryConfig from './azureActiveDirectoryConfig';
import { PublicClientApplication } from '@azure/msal-browser';

export class AzureActiveDirectoryProvider implements IAuthProvider {
  private app: PublicClientApplication;
  private scope: string;

  constructor(private config: AzureActiveDirectoryConfig) {
    this.app = new PublicClientApplication({
      auth: {
        clientId: config.clientId, // '349a146b-3f06-455c-a26a-e3a410d8eb44',
        authority: config.authority,
        redirectUri: config.redirectUri
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    });
    this.scope = config.scope;
  }

  login(): void {
    this.app.loginRedirect({
      scopes: this.scope.split(' ')
    });
  }

  logout(): void {
    const username = this.app.getAllAccounts()[0].username;
    const account = this.app.getAccountByUsername(username);
    this.app.logoutRedirect({
      account: account
    });
  }

  async renewTokensAsync(): Promise<TokenResult> {
    throw new Error('Method not implemented.');
  }

  resetPasswordAsync(email: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  handleAuthenticationResult(callback: (result: TokenResult, error: any) => void): void {
    this.app
      .handleRedirectPromise()
      .then(response => {
        if (this.app.getAllAccounts().length === 0) {
          console.warn('Cannot handle AAD Login Result. No logged in account found.');
          return;
        }

        const username = this.app.getAllAccounts()[0].username;
        const account = this.app.getAccountByUsername(username);

        this.app
          .acquireTokenSilent({
            scopes: this.scope.split(' '),
            account: account
          })
          .then(result => {
            callback(
              {
                accessToken: result.accessToken,
                idToken: result.idToken,
                expiresIn: result.expiresOn.getTime(),
                scope: result.scopes.join(' '),
                tokenType: ''
              },
              null
            );
          });
      })
      .catch(error => {
        console.error(error);
      });
  }
}
