import { Subscription } from 'rxjs';

export class SubscriptionManager {
  private subscriptions: Subscription;

  add(...subscriptions: Subscription[]): void {
    if (this.subscriptions.closed) {
      this.subscriptions = new Subscription();
    }

    subscriptions.forEach((subscription) => {
      this.subscriptions.add(subscription);
    });
  }

  unsubscribeAll(): void {
    this.subscriptions.unsubscribe();
  }

  constructor() {
    this.subscriptions = new Subscription();
  }
}
