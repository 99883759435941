import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { UserService } from './services/api/user.service';
import { User } from './models/user';
import { Router, Event, NavigationEnd } from '@angular/router';
import { GlobalsService } from './services/globals.service';
import { MessageService } from './services/message.service';
import { environment } from 'src/environments/environment';
import { SubscriptionManager } from './helpers/subscriptionManager';
import { LoggerService } from './services/logger.service';
import { MatDialogRef } from '@angular/material';
import { DefaultDialogComponent } from './dialogs/default.dialog.component';
import LaunchContext from './models/launchContext';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  isLoading: boolean;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isToolbarVisible = true;
  isLogoVisible = true;
  isBackgroundVisible = true;
  isOnline = true;
  currentUser: User;
  environmentName: string;

  private subscriptionManager = new SubscriptionManager();
  private offlineDialog: MatDialogRef<DefaultDialogComponent>;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private globals: GlobalsService,
    private messageService: MessageService,
    private loggerService: LoggerService,
    private router: Router
  ) {
    // Subscribe to Login status changes to react and display navigation bar content properly
    this.subscriptionManager.add(
      this.authService.isLoggedIn.subscribe(value => this.handleLoginChangedAsync(value)),
      this.globals.getIsLoading().subscribe(value => (this.isLoading = value))
    );

    // Environment
    this.environmentName = environment.name;

    // Handle authentication, if returning from Login page
    this.authService.handleAuthentication();

    // Handle routing events
    this.router.events.subscribe(value => this.onRouteChanged(value));

    // Hide Logo and toolbar, when in Microsoft Teams Mode
    this.isLogoVisible = !LaunchContext.isTeamsMode;
    this.isToolbarVisible = !LaunchContext.isTeamsMode;
  }

  private async onRouteChanged(value: Event) {
    if (value instanceof NavigationEnd) {
      // Hide toolbar and background for listeners
      this.isToolbarVisible =
        value.urlAfterRedirects.indexOf('/listener/') < 0 &&
        value.urlAfterRedirects.indexOf('/connect') < 0 &&
        !LaunchContext.isTeamsMode;
      this.isBackgroundVisible = value.urlAfterRedirects.indexOf('/listener/') < 0;
    }
  }

  private async handleLoginChangedAsync(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
    this.isAdmin = false;

    if (isLoggedIn) {
      try {
        this.currentUser = await this.userService.getCurrentUserAsync();
        this.isAdmin = this.currentUser.isAdmin;
        if (this.currentUser.settings.allowTracking) {
          this.loggerService.setUser(this.currentUser.userId, this.currentUser.loggingLevel);
        }
      } catch (error) {
        this.messageService.showToast(error.message);
      }
    }

    // Navigate to the login page, if user is not logged in.
    if (
      !isLoggedIn &&
      window.location.pathname.indexOf('listener') < 0 && // Exception: Listers can access the app without logging in.
      window.location.pathname.indexOf('settings') < 0 && // Exception: Settings can be accessed without logging in.
      window.location.pathname.indexOf('about') < 0 && // Exception: About page can be accessed without logging in.
      window.location.pathname.indexOf('callback') < 0 && // Exception: Callback is used for authentication.
      window.location.pathname.indexOf('qrcode') < 0 && // Exception: Users can access QR Code without logging in
      window.location.pathname.indexOf('connect') < 0 && // Exception: Users can connect to events
      window.location.pathname.indexOf('login') < 0
    ) {
      // Exception: User is already is on the login page.
      console.log('No Login data found on ' + window.location.pathname + '. Redirecting to the login page...');
      this.router.navigate(['/login']);
    }
  }

  private onIsOnlineChanged(isOnline: boolean): void {
    this.isOnline = isOnline;
    if (!isOnline) {
      this.offlineDialog = this.messageService.showNoConnectionDialog();
    } else {
      if (this.offlineDialog) {
        this.messageService.showToast('You are back online! Good Job!');
        this.offlineDialog.close();
      }
    }
  }

  navigateHome() {
    if (this.authService.isLoggedIn.value === true) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  logout() {
    this.authService.logout();
  }

  showNoConnectionMessage(): void {
    this.messageService.showToast('You are offline. Please reconnect.');
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }
}
