import { Sentence } from './sentence';
import { Language } from './language';

/**
 * Sentence that gets displayed in the listener view
 */
export class DisplaySentence {
  id: string;
  order: number;
  creation: string;
  isComplete: boolean;
  text: string;
  textDirection: string;

  constructor(sentence: Sentence, language: Language) {
    this.id = sentence.id;
    this.order = sentence.order;
    this.creation = sentence.creation;
    this.isComplete = sentence.isComplete;
    this.text = sentence.text;
    this.textDirection = sentence.textDirection;

    if (language) {
      // Translation has been requested.
      // We use the text direction from the translation language.
      this.textDirection = language.direction;

      // 1. Check, if the requested translation language is the same as the recording language
      // If so, just return the original text.
      if (language.code === sentence.language) {
        return;
      }

      // 2. Check, if a translation for the requested language is available. If so, we use the
      // text from the translations and the direction from the language.
      if (sentence.translations) {
        // Allow for both uppercase and lowercase language codes (legacy technical debt)
        const translation =
          sentence.translations[language.translationLanguageCode] ||
          sentence.translations[language.translationLanguageCode.toLowerCase()];
        this.text = translation || '';
        return;
      }

      // 3. If no translation is available, we display no text.
      this.text = '';
    }
  }
}
