import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

export enum LoggingLevel {
  None = 'None',
  Errors = 'Errors',
  Warnings = 'Warnings',
  Info = 'Info'
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private level: LoggingLevel = LoggingLevel.None;
  private logToAnalytics = true;
  private appInsights: ApplicationInsights;

  constructor() {
    const appInsightsInstrumentationKey = environment.appInsightsInstrumentationKey;
    if (appInsightsInstrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
          // Don't add the traceparent header to requests outside the current domain, as some
          // services like Auth0 don't support it
          enableCorsCorrelation: false,
          extensions: [],
          enableAutoRouteTracking: true // Automatically track route changes
        }
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();
      this.appInsights.addTelemetryInitializer(envelope => {
        envelope.tags['ai.cloud.role'] = 'web';
      });
    } else {
      console.log('No Application Insights Instrumentation Key has been provided.');
    }
    this.level = environment.logLevel;
  }

  /*
   * Sets logging context to the current user
   * @param userId the id of the currently logged in user
   * @param logLevel override the default logging level of this environment
   */
  setUser(userId: string, logLevel?: LoggingLevel) {
    if (this.appInsights) {
      this.appInsights.setAuthenticatedUserContext(userId, null, true);
      if (logLevel) {
        this.level = logLevel;
      } else {
        this.level = environment.logLevel;
      }

      console.log(`Detailed user logging activated with logging level ${this.level}`);
    }
  }

  clearUserContext() {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
      console.log(`Detailed user logging deactivated.`);
    }
  }

  logError(message: any, save: boolean = false, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Errors, save, optionalParams);
  }

  logWarning(message: any, save: boolean = false, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Warnings, save, optionalParams);
  }

  logInfo(message: any, save: boolean = false, ...optionalParams: any[]) {
    this.log(message, LoggingLevel.Info, save, optionalParams);
  }

  logEvent(name: string) {
    this.appInsights.trackEvent({ name: name });
  }

  private log(message: any, level = LoggingLevel.Warnings, save: boolean = false, ...optionalParams: any[]) {
    if (this.shouldLog(level)) {
      switch (level) {
        case LoggingLevel.Errors:
          if (save && this.appInsights) {
            this.appInsights.trackException({ exception: new Error(message) });
          }
          console.error(message, optionalParams);
          break;
        case LoggingLevel.Warnings:
          if (save && this.appInsights) {
            this.logEvent(message);
          }
          console.warn(message, optionalParams);
          break;
        case LoggingLevel.Info:
          if (save && this.appInsights) {
            this.appInsights.trackTrace(message);
          }
          // tslint:disable-next-line:no-console
          console.info(message, optionalParams);
          break;
        default:
          if (save && this.appInsights) {
            this.appInsights.trackTrace(message);
          }
          // tslint:disable-next-line:no-console
          console.debug(message, optionalParams);
      }
    }

    if (save && this.appInsights) {
      this.appInsights.flush();
    }
  }

  private shouldLog(level: LoggingLevel) {
    if (this.level === LoggingLevel.None) {
      return false;
    } else if (this.level === LoggingLevel.Errors) {
      return level === LoggingLevel.Errors;
    } else if (this.level === LoggingLevel.Warnings) {
      return level === LoggingLevel.Errors || level === LoggingLevel.Warnings;
    } else if (this.level === LoggingLevel.Info) {
      return level === LoggingLevel.Errors || level === LoggingLevel.Warnings || level === LoggingLevel.Info;
    } else {
      return true;
    }
  }
}
