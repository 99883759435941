export enum VocabularyBookState {
  notStarted = 'NotStarted',
  succeeded = 'Succeeded',
  training = 'Training',
  failed = 'Failed',
  creatingEndpoint = 'CreatingEndpoint'
}

export class VocabularyBook {
  id: string;
  name: string;
  language: string;
  state: VocabularyBookState;
  words: string[];
  endpoint: string;
  speechConfigId: string;
  isDeleted: boolean;
  lastUsed: Date;
  createdAt: Date;

  constructor() {
    this.state = VocabularyBookState.notStarted;
    this.isDeleted = false;
    this.words = [];
  }
}

export class VocabularyBookStateUtil {
  public static toString(state: VocabularyBookState): string {
    switch (state) {
      case VocabularyBookState.notStarted:
        return 'not started';
      case VocabularyBookState.succeeded:
        return 'succeeded';
      case VocabularyBookState.training:
        return 'in progress';
      case VocabularyBookState.failed:
        return 'failed';
      case VocabularyBookState.creatingEndpoint:
        return 'in finalizing state';
    }
  }
}
