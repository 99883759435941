import { Injectable } from '@angular/core';
import { Sentence } from '../models/sentence';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { DisplaySentence } from '../models/displaySentence';
import { Event } from '../models/event';
import { GlobalsService } from './globals.service';
import { BackendMessage, BackendMessageType } from '../models/backendMessage';
import { moveOrderNumberIfNecessary, sort } from '../helpers/sentenceHelpers';
import { Language } from '../models/language';
import { SignalRRetryPolicy } from '../helpers/signalRRetryPolicy';
import { ConnectionState, ConnectionStateUtils } from '../helpers/connectionState';

@Injectable({
  providedIn: 'root'
})
export class ListenerService {
  private readonly TYPEWRITER_DELAY = 100;

  private passwordConfirmedSource = new Subject<string>();
  private passwordErrorSource = new Subject<string>();
  passwordConfirmed$ = this.passwordConfirmedSource.asObservable();
  passwordErrored$ = this.passwordErrorSource.asObservable();

  private hubConnection: HubConnection;
  private translationLanguage: Language;
  private sentences: DisplaySentence[] = [];
  private lastClearedSentenceId = '-1';

  delay = 0;
  behaviorSentences: BehaviorSubject<DisplaySentence[]> = new BehaviorSubject<DisplaySentence[]>(
    this.sentences
  );
  connectionState: BehaviorSubject<ConnectionState> = new BehaviorSubject<ConnectionState>(
    ConnectionState.Disconnected
  );

  private clearedSentencesMethod = 'ClearedSentences';

  constructor(private globals: GlobalsService) {}

  async connectAsync(event: Event) {
    try {
      // Prepare SignalR connection to the Streaming Backend
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(environment.streamingServiceUrl + '/listener?eventId=' + event.id)
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect(new SignalRRetryPolicy())
        .build();

      // The server sends a message to the clients at least every 3s. It is recommended to consider
      // a connection as disrupted, when not receiving a message after this time x2.
      this.hubConnection.serverTimeoutInMilliseconds = 6000;
      this.hubConnection.keepAliveIntervalInMilliseconds = 3000;

      this.hubConnection.onreconnecting(err => {
        console.log('Reconnecting...');
        console.log('State: ', this.hubConnection.state);
        this.connectionState.next(
          ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state)
        );
      });
      this.hubConnection.onreconnected(id => {
        console.log('Reconnected.');
        console.log('State: ', this.hubConnection.state);
        this.connectionState.next(
          ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state)
        );
      });
      this.hubConnection.onclose(err => {
        console.log('WebSocket connection to Streaming Service has been closed.');
        console.log('State: ', this.hubConnection.state);
        this.connectionState.next(
          ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state)
        );
      });

      // Subscribe to ReceivedSentence event from stream
      this.hubConnection.on('ReceivedSentence', (sentence: Sentence) => {
        this.onReceivedSentence(sentence);
      });

      // Subscribe to DeletedSentence event from stream
      this.hubConnection.on('DeletedSentence', (sentenceId: string) => {
        this.onSentenceDeleted(sentenceId);
      });

      this.hubConnection.on('ReceivedMessage', (message: BackendMessage) => {
        if (message.type === BackendMessageType.WrongEventPassword) {
          this.passwordErrorSource.next('Wrong password.');
        } else if (message.type === BackendMessageType.CorrectEventPassword) {
          this.passwordConfirmedSource.next('Confirmed.');
        }
      });

      this.hubConnection.on(this.clearedSentencesMethod, () => {
        this.clearSentences();
      });
    } catch (err) {
      throw new Error(err.message);
    }

    try {
      // Start streaming connection
      await this.hubConnection.start();
      this.connectionState.next(
        ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state)
      );
    } catch (error) {
      throw new Error('An error occurred while trying to connect to the stream.');
    }
  }

  stop() {
    // Close streaming connection
    this.hubConnection.stop();
  }

  private async onReceivedSentence(sentence: Sentence) {
    // Artificially delay the processing of the received sentence
    if (this.delay > 0) {
      await this.globals.sleep(this.delay);
    }

    // Move the order number if necessary
    moveOrderNumberIfNecessary(this.sentences, sentence);

    // Check, if sentence has be added at the end
    let isAddedAtTheEnd = true;
    if (this.sentences.length > 0) {
      const lastSentence = this.sentences[this.sentences.length - 1];
      if (lastSentence.order > sentence.order) {
        isAddedAtTheEnd = false;
      } else if (lastSentence.order === sentence.order && lastSentence.text.length > 0) {
        isAddedAtTheEnd = false;
      }
    }

    // Create a DisplaySentence for the incoming Sentence
    let displaySentence = new DisplaySentence(sentence, this.translationLanguage);
    const existingSentence = this.sentences.find(x => x.id === sentence.id);

    // Typewriter style
    // Only show sentence in artificial typewriter style, when it is added at the end of the array
    // and is complete, and did not have any existing (or empty) subsentence before in the array.
    // This usually happens, if the corrector approves or injects full sentences.
    if (
      isAddedAtTheEnd &&
      sentence.isComplete &&
      (!existingSentence || existingSentence.text.length === 0)
    ) {
      // Split sentence up by words
      const words = displaySentence.text.split(' ');

      // Add words one after another
      let text = '';
      for (let i = 0; i < words.length; i++) {
        text += words[i];
        if (i !== words.length - 1) {
          text += ' '; // Don't add a space behind the last word
        }

        // HACK: We need to create a new DisplaySentence object here, as only this triggers the
        // 'change' event in the frontend which scrolls down automatically
        displaySentence = new DisplaySentence(sentence, this.translationLanguage);
        displaySentence.text = text;
        this.addSentence(displaySentence);
        await this.globals.sleep(this.TYPEWRITER_DELAY);
      }
    } else {
      // No typewriter style. Add sentence as it is.
      this.addSentence(displaySentence);
    }
  }

  private async onSentenceDeleted(sentenceId: string) {
    // Check, if sentence was already in the sentence array.
    const existingSentence = this.sentences.find(x => x.id === sentenceId);

    if (!existingSentence) {
      return;
    }

    // remove the sentence from the array
    const index = this.sentences.indexOf(existingSentence);
    this.sentences.splice(index, 1);

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  public validateEventPassword(password: string) {
    return this.hubConnection.invoke('ValidateEventPassword', password);
  }

  private async clearSentences() {
    // Save id of last cleared sentence to ensure it is not displayed again on backend change
    if (this.sentences.length > 0) {
      this.lastClearedSentenceId = this.sentences[this.sentences.length - 1].id;
    }
    this.sentences.length = 0;
    this.behaviorSentences.next(this.sentences);
  }

  private addSentence(sentence: DisplaySentence) {
    // Check if sentence has been cleared before
    if (this.lastClearedSentenceId === sentence.id) {
      return;
    }

    // Check, if sentence was already in the sentence array.
    // If so, update the existing sentence. Otherwise add a new one.
    const existingSentence = this.sentences.find(x => x.id === sentence.id);
    const index = this.sentences.indexOf(existingSentence);
    if (existingSentence) {
      this.sentences[index] = sentence;
    } else {
      this.sentences.push(sentence);
    }

    // Sort sentences by order and creation days
    sort(this.sentences);

    // Update sentences
    this.behaviorSentences.next(this.sentences);
  }

  clear(): void {
    this.sentences.length = 0;

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  changeTranslationLanguage(language: Language) {
    this.translationLanguage = language;
  }
}
