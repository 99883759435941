import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDragAndDropContainer]'
})
export class DragAndDropContainerDirective {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDragOver = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDragLeave = new EventEmitter<any>();

  constructor() {}

  @HostListener('dragover', ['$event']) onDragOverListener(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.onDragOver.emit();
  }

  @HostListener('dragleave', ['$event']) public onDragLeaveListener(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.onDragLeave.emit();
  }

  @HostListener('drop', ['$event']) public onDropListener(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.onDragLeave.emit();
  }
}
