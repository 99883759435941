import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalsService } from 'src/app/services/globals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/api/admin.service';
import { Event } from 'src/app/models/event';
import { User } from 'src/app/models/user';
import { MessageService } from 'src/app/services/message.service';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/api/event.service';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { Recording } from 'src/app/models/recording';

@Component({
  selector: 'app-admin-eventdetails',
  templateUrl: './admin-eventdetails.component.html',
  styleUrls: ['./admin-eventdetails.component.scss']
})
export class AdminEventDetailsComponent implements OnInit, OnDestroy {
  event: Event = new Event();
  user: User = new User();
  recordings: Recording[] = [];
  eventUrlDomain: string;
  isLoading: boolean;

  private subscriptionManager = new SubscriptionManager();

  constructor(
    public globals: GlobalsService,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private eventService: EventService,
    private messageService: MessageService
  ) {
    this.eventUrlDomain = this.eventService.buildListenerUrlFor(this.event);
  }

  async ngOnInit() {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));

    try {
      this.globals.setIsLoading(true);
      const eventId = this.route.snapshot.paramMap.get('id');

      this.event = await this.adminService.getEventByIdAsync(eventId);
      this.user = await this.adminService.getUserAsync(this.event.userId);
      this.recordings = await this.adminService.getRecordingsByEventIdAsync(eventId);
    } catch (error) {
      if (error.status === 403) {
        // User is not allowed to access. Navigate back to home
        this.messageService.showToast('No access');
        this.router.navigate(['/']);
        return;
      } else {
        this.messageService.showToast('An error occurred.');
      }
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }

  async showJson(): Promise<void> {
    const json = JSON.stringify(this.event, null, 2);
    await this.messageService.showDialogAsync('JSON', json, 'Close');
  }
}
