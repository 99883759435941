export enum TranscriptFormat {
  TXT = 'TXT',
  PDF = 'PDF',
  SRT = 'SRT',
  VTT = 'VTT'
}

export class TranscriptDownloadRequest {
  format: TranscriptFormat;
  translationLanguageCode: string;
}
