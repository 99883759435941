import { Injectable } from '@angular/core';
import { Sentence } from '../models/sentence';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import { AuthService } from './auth.service';
import { Event } from '../models/event';
import { CorrectorSentence } from '../models/correctorSentence';
import { SentenceService } from './api/sentence.service';
import { Language } from '../models/language';
import { sort } from '../helpers/sentenceHelpers';
import UpdatedSentenceOrderInformation from '../models/updatedSentenceOrderInformation';
import * as _ from 'lodash';
import convert from 'pcm-convert';
import { base64ToArrayBuffer } from '../helpers/arrayHelpers';
import { ConnectionState, ConnectionStateUtils } from '../helpers/connectionState';
import { RecorderState } from '../models/recorderState';
import { SignalRRetryPolicy } from '../helpers/signalRRetryPolicy';

@Injectable({
  providedIn: 'root'
})
export class CorrectorService {
  private event: Event;
  private autoApprovalSeconds = 5;
  private streamConnection: HubConnection;
  private sentences: CorrectorSentence[] = [];
  private readonly playContext: AudioContext;

  private clearSentencesMethod = 'ClearSentences';
  private readonly startAudioStreamingMethod: string = 'StartAudioStreaming';
  private readonly stopAudioStreamingMethod: string = 'StopAudioStreaming';

  behaviorSentences: BehaviorSubject<CorrectorSentence[]> = new BehaviorSubject<
    CorrectorSentence[]
  >(this.sentences);
  isAutoApproval: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  currentLanguage: Subject<Language> = new Subject<Language>();
  connectionState: BehaviorSubject<ConnectionState> = new BehaviorSubject<ConnectionState>(
    ConnectionState.Disconnected
  );
  recorderState: BehaviorSubject<RecorderState> = new BehaviorSubject<RecorderState>(
    new RecorderState()
  );
  manuallyInserted: Subject<CorrectorSentence> = new Subject<CorrectorSentence>();

  private get activeTimerSentence(): CorrectorSentence | undefined {
    return this.sentences.find(x => x.isTimerActive);
  }

  constructor(private authService: AuthService, private sentenceService: SentenceService) {
    this.isAutoApproval.subscribe(value => this.onIsAutoApprovalChanged(value));

    // To support Safari, AudioContext has to be declared like this.
    // Details: https://stackoverflow.com/questions/48757933/audiocontext-issue-on-safari
    this.playContext = new (window['AudioContext'] || window['webkitAudioContext'])();
  }

  async connectAsync(event: Event) {
    this.event = event;

    // Prepare SignalR connection to the Streaming Backend
    this.streamConnection = new HubConnectionBuilder()
      .withUrl(environment.streamingServiceUrl + '/corrector?eventId=' + event.id, {
        accessTokenFactory: async () => await this.authService.getAccessTokenAsync()
      })
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect(new SignalRRetryPolicy())
      .build();

    // The server sends a message to the clients at least every 3s. It is recommended to consider
    // a connection as disrupted, when not receiving a message after this time x2.
    this.streamConnection.serverTimeoutInMilliseconds = 6000;
    this.streamConnection.keepAliveIntervalInMilliseconds = 3000;

    // React on socket state changes
    this.streamConnection.onreconnecting(err => this.onReconnecting());
    this.streamConnection.onreconnected(async id => await this.onReconnectedAsync());
    this.streamConnection.onclose(err => this.onClose());

    // Subscribe to events from stream
    this.streamConnection.on(
      'ReceivedSentences',
      (sentences: Sentence[], isManuallyInserted: boolean) => {
        this.addSentences(sentences, isManuallyInserted);
      }
    );

    // Subscribe to recorder state changes
    this.streamConnection.on('ChangedRecorderState', (state: RecorderState) => {
      this.recorderState.next(state);
    });

    this.streamConnection.on('ChangedLanguage', (language: Language) => {
      this.currentLanguage.next(language);
    });

    // Subscribe to DeletedSentences event from stream
    this.streamConnection.on('DeletedSentences', (sentenceIds: string[]) => {
      sentenceIds.forEach(sentenceId => {
        this.removeSentence(sentenceId);
      });
    });

    // Subscribe to FocusedSentence event from stream
    this.streamConnection.on('FocusedSentence', (sentenceId: string) => {
      this.lockSentence(sentenceId);
    });

    // Subscribe to UnfocusedSentence event from stream
    this.streamConnection.on('UnfocusedSentence', (sentenceId: string) => {
      this.unlockSentence(sentenceId);
    });

    // Subscribe to UpdatedSentence event from stream
    this.streamConnection.on('UpdatedSentence', (sentence: Sentence) => {
      this.updateSentence(sentence);
    });

    // Subscribe to UpdatedSentenceOrder event from stream
    this.streamConnection.on(
      'UpdatedSentenceOrder',
      (updatedSentences: UpdatedSentenceOrderInformation[]) => {
        this.updateSentencesOrders(updatedSentences);
      }
    );

    // Subscribe to event from stream
    this.streamConnection.on('ReceivedAudioChunk', (audioChunkBase64: string) => {
      this.handleAudioChunkReceived(audioChunkBase64);
    });

    // Start streaming connection
    await this.streamConnection.start();
    this.connectionState.next(
      ConnectionStateUtils.fromHubConnectionState(this.streamConnection.state)
    );
  }

  close() {
    this.streamConnection.stop();
  }

  private addSentences(sentences: Sentence[], isManuallyInserted: boolean) {
    // Transform incoming sentence into the enriched 'correctorSentence', which has additional
    // properties like with auto-approval timers etc.
    const correctorSentences = sentences.map(sentence => new CorrectorSentence(sentence));

    correctorSentences.forEach(correctorSentence => {
      // Check, if sentence was already in the sentence array
      const existingSentence = this.sentences.find(x => x.id === correctorSentence.id);
      if (existingSentence) {
        // Do not fully replace the sentence with the incoming one, as timer and other
        // properties might already be set or started. Just override the important values with
        // the ones from the corrected sentence.
        existingSentence.updateFrom(correctorSentence);
        // check if the updated sentence isApproved, but a timer is running
        if (existingSentence.isApproved && existingSentence.isTimerActive) {
          existingSentence.approve();
          this.startApprovalTimer();
        }
      } else {
        // No already existing sentence found. Just add it as a new one.
        this.sentences.push(correctorSentence);
      }
    });

    // Sort sentences
    sort(this.sentences);

    // Find the sentences that has just been added to the list of corrector sentences.
    // Do this AFTER sorting, because we might need to take a look at the previous sentence
    // for checking if we should start the auto-approval timer or not.
    const addedSentences = this.sentences.filter(
      x => correctorSentences.find(y => y.id === x.id) && x.isComplete
    );
    addedSentences.forEach(addedSentence => {
      // If sentence is complete, we maybe have to start the approval timer
      this.startApprovalTimer();
    });

    if (isManuallyInserted) {
      const lastManuallyInsertedSentence = _.last(sentences);
      const correctorSentence = correctorSentences.find(
        x => x.id === lastManuallyInsertedSentence.id
      );
      this.manuallyInserted.next(correctorSentence);
    }

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  /**
   * Approves a sentence and sends them to the listeners.
   * @param sentence The sentence to approve
   */
  approve(sentence: CorrectorSentence) {
    // Set sentence as approved
    sentence.approve();

    // Send approved sentence to the Backend WebSocket
    this.streamConnection.send('ApproveSentence', sentence);

    // continue with the next sentence
    this.startApprovalTimer();
  }

  /**
   * Removes a sentence from the list of sentences for the current corrector
   * but not from the Listener's or other corrector's sentences.
   * but it will notify via SignalR that the sentence was deleted, so that
   * all listeners and other correctors will also know that this sentence was
   * deleted, so that they will be able to handle this event
   * @param sentence The sentence to remove
   */
  remove(sentence: CorrectorSentence) {
    // locally remove the sentence
    this.removeSentence(sentence.id);

    // invoke corrector hub
    this.streamConnection.send('DeleteSentence', sentence.id);
  }

  private removeSentence(sentenceId: string) {
    // Find sentence in array
    const sentence = this.sentences.find(s => s.id === sentenceId);

    // if the sentence was not found => nothing to do
    if (!sentence) {
      return;
    }

    // remove the sentence from the array
    const index = this.sentences.indexOf(sentence);
    this.sentences.splice(index, 1);

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  /**
   * Notifies backend to clear all listeners
   * Backend will send message to listener through listenerhub
   * See clearSentence in listener.service.ts
   */
  public clearSentences(): void {
    console.log('clear sentences');
    this.streamConnection.send(this.clearSentencesMethod);
  }

  /**
   * Inserts a new empty sentence between two sentences
   * @param previousSentence The sentence before the new empty sentence
   */
  async insertSentenceAfter(previousSentence: CorrectorSentence, text: string = '') {
    let nextSentenceOrder = -1;

    const previousSentenceIndex = this.sentences.indexOf(previousSentence);
    if (previousSentence && this.sentences.length > previousSentenceIndex + 1) {
      const nextSentence = this.sentences[previousSentenceIndex + 1];
      nextSentenceOrder = nextSentence.order;
    }

    const sentence = new Sentence(
      '',
      this.event.language,
      Date.now.toString(),
      0,
      text,
      text,
      null,
      true,
      false,
      'ltr'
    );

    this.streamConnection.send(
      'InsertSentenceBetween',
      sentence,
      previousSentence,
      nextSentenceOrder
    );
  }

  changeRecordingLanguageAsync(language: Language): Promise<void> {
    return this.streamConnection.send('ChangeLanguage', language);
  }

  onIsAutoApprovalChanged(value: boolean) {
    if (this.isAutoApproval.value) {
      this.startApprovalTimer();
    } else {
      this.pauseApprovalTimer();
    }
  }

  startApprovalTimer(continueActiveTimerSentence: boolean = false): void {
    // if auto approval is disabled => nothing to do
    if (!this.isAutoApproval.value) {
      return;
    }

    // if active timer sentence found
    if (this.activeTimerSentence) {
      // and the call was from the unfocussed event => continue the timer
      if (continueActiveTimerSentence) {
        this.activeTimerSentence.startApprovalTimer();
      }
      return;
    }

    // get the next pending sentence
    const nextTimerSentence: CorrectorSentence = _.minBy(
      this.sentences.filter(x => x.isComplete && !x.isApproved),
      x => x.order
    );

    // if no next timer sentence found => nothing to do
    if (!nextTimerSentence) {
      return;
    }

    // if the next timer sentence is currently focused => nothing to do
    if (nextTimerSentence.isFocussed) {
      return;
    }

    // if the next timer sentence is locked => nothing to do
    if (nextTimerSentence.isLocked) {
      return;
    }

    // This only creates a timer for the sentence but does not start it yet.
    nextTimerSentence.createApprovalTimer(this.autoApprovalSeconds, () => {
      this.approve(nextTimerSentence);
    });

    nextTimerSentence.startApprovalTimer();
  }

  pauseApprovalTimer(): void {
    // if no timer running => nothing to do
    if (!this.activeTimerSentence) {
      return;
    }

    this.activeTimerSentence.pauseApprovalTimer();
  }

  setAutoApproveTime(seconds: number) {
    this.autoApprovalSeconds = seconds;
  }

  /**
   * This function will notify the corrector hub that this sentence was focused
   * @param sentence
   */
  sentenceFocused(sentence: CorrectorSentence): void {
    sentence.focus();
    this.streamConnection.send('SentenceFocused', sentence.id);
    // While this sentence is being edited, pause auto-approval timer for this and all following sentences
    // If the sentence has already been approved, do not stop the timers.
    if (sentence.isApproved) {
      return;
    }
    // If the focussed sentence is a sentence after the activeTimerSentence => don't stop
    if (this.activeTimerSentence && this.activeTimerSentence.order < sentence.order) {
      return;
    }
    this.pauseApprovalTimer();
  }

  /**
   * This function will notify the corrector hub that this sentence was unfocused
   * @param sentence
   */
  sentenceUnfocused(sentence: CorrectorSentence): void {
    sentence.unFocus();
    this.streamConnection.send('SentenceUnfocused', sentence.id);
    this.startApprovalTimer(true);
  }

  lockSentence(sentenceId: string): void {
    const sentence = this.sentences.find(x => x.id === sentenceId);

    // if the sentence was not found => do nothing
    if (!sentence) {
      return;
    }

    sentence.lock();

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);

    // pause the approval timer if the sentence is not approved
    // and the sentence is the active one
    if (!sentence.isApproved && this.activeTimerSentence === sentence) {
      this.pauseApprovalTimer();
    }
  }

  unlockSentence(sentenceId: string): void {
    const sentence = this.sentences.find(x => x.id === sentenceId);

    // if the sentence was not found => do nothing
    if (!sentence) {
      return;
    }

    sentence.unlock();

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);

    // contine the approval timer
    this.startApprovalTimer(true);
  }

  sentenceUpdated(sentence: CorrectorSentence): void {
    this.streamConnection.send('SentenceUpdated', sentence);
  }

  updateSentence(sentence: Sentence): void {
    const correctorSentence = this.sentences.find(x => x.id === sentence.id);

    // if the sentence was not found => do nothing
    if (!correctorSentence) {
      return;
    }

    correctorSentence.updateFrom(sentence);

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  updateSentencesOrders(updatedSentences: UpdatedSentenceOrderInformation[]) {
    // update order
    updatedSentences.forEach(updatedSentence => {
      const existingSentence = this.sentences.find(x => x.id === updatedSentence.sentenceId);
      if (!existingSentence) {
        return;
      }
      existingSentence.order = updatedSentence.order;
    });

    // Sort sentences
    sort(this.sentences);

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  getSentenceAfter(sentence: Sentence): Sentence {
    const index = this.sentences.findIndex(x => x.id === sentence.id);
    if (index >= 0 && this.sentences.length > index) {
      const nextSentence = this.sentences[index + 1];
      return nextSentence;
    }

    return null;
  }

  // #region Audio streaming
  public subscribeToAudioStreaming(): void {
    this.streamConnection.send(this.startAudioStreamingMethod);
  }

  public unsubscribeFromAudioStreaming(): void {
    this.streamConnection.send(this.stopAudioStreamingMethod);
  }

  private handleAudioChunkReceived(audioChunkBase64: string): void {
    const backToFloat = convert(
      new Int16Array(base64ToArrayBuffer(audioChunkBase64)),
      'int16',
      'float32'
    );

    const audioChunkSource = this.playContext.createBufferSource();
    audioChunkSource.buffer = new AudioBuffer({
      numberOfChannels: 1,
      length: backToFloat.length,
      sampleRate: 16000
    });

    audioChunkSource.buffer.copyToChannel(backToFloat, 0);

    audioChunkSource.connect(this.playContext.destination);
    audioChunkSource.start(0);
  }
  // #endregion

  private onReconnecting(): void {
    console.log('Connection to the server lost. reconnecting...');
    this.connectionState.next(
      ConnectionStateUtils.fromHubConnectionState(this.streamConnection.state)
    );
  }

  private async onReconnectedAsync(): Promise<void> {
    console.log('Reconnected.');
    this.connectionState.next(
      ConnectionStateUtils.fromHubConnectionState(this.streamConnection.state)
    );
  }

  private onClose(): void {
    console.log('WebSocket connection to Streaming Service has been closed.');
    this.connectionState.next(
      ConnectionStateUtils.fromHubConnectionState(this.streamConnection.state)
    );
  }
}
