import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewChecked
} from '@angular/core';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ActivatedRoute } from '@angular/router';
import { CorrectorService } from 'src/app/services/corrector.service';
import { MessageService } from 'src/app/services/message.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { EventService } from 'src/app/services/api/event.service';
import { Event } from 'src/app/models/event';
import { CorrectorSentence } from 'src/app/models/correctorSentence';
import { SettingsService } from 'src/app/services/api/settings.service';
import { LanguageService } from 'src/app/services/api/language.service';
import { Language } from 'src/app/models/language';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { UserService } from '../../services/api/user.service';
import { ConnectionState } from 'src/app/helpers/connectionState';
import { environment } from 'src/environments/environment';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CorrectorSentenceComponent } from '../corrector-sentence/corrector-sentence.component';
import { RecorderState } from 'src/app/models/recorderState';
import CorrectorSentenceSplitParams from '../corrector-sentence/correctorSentenceSplitParams';

const autoApprovalTimes: number[] = [1, 2, 3, 4, 5, 10, 15, 20, 30];

@Component({
  selector: 'app-corrector',
  templateUrl: './corrector.component.html',
  styleUrls: ['./corrector.component.scss']
})
export class CorrectorComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChildren('correctorSentence') sentenceElements: QueryList<CorrectorSentenceComponent>;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('scrollParent', { static: false }) scrollParent: ElementRef;

  isLoading = false;
  sentences: CorrectorSentence[] = [];
  focussedSentence: CorrectorSentence;
  isAutoApproval: boolean;
  isScrollDownButtonVisible: boolean;
  event: Event;
  autoAppovalTimeOptions: number[];
  selectedAutoAppovalTimerDuration: number;
  allLanguages: Language[];
  autoScroll = true;
  isStreamingAudio = false;
  isDebug = false;
  isInitialScrollerResizeDone = false;

  private subscriptionManager = new SubscriptionManager();

  // Connection
  connectionState: ConnectionState = ConnectionState.Disconnected;
  isConnected = false;
  isConnecting = false;
  isReconnecting = false;
  isDisconnected = true;
  recorderState = new RecorderState();

  constructor(
    private route: ActivatedRoute,
    private globals: GlobalsService,
    public correctorService: CorrectorService,
    private eventService: EventService,
    private messageService: MessageService,
    private hotkeysService: HotkeysService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private userService: UserService
  ) {
    this.setupHotkeys();
    this.autoAppovalTimeOptions = autoApprovalTimes;
    this.allLanguages = [];
    this.event = new Event();
    this.isDebug = environment.debugMode;
  }

  async ngOnInit() {
    // Refresh beta flags
    await this.userService.getCurrentUserAsync();

    this.subscriptionManager.add(
      this.globals.getIsLoading().subscribe(value => (this.isLoading = value)),
      this.correctorService.isAutoApproval.subscribe(value => {
        this.isAutoApproval = value;
      }),
      this.correctorService.behaviorSentences.subscribe(sentences =>
        this.onSentencesChanged(sentences)
      ),
      this.correctorService.currentLanguage.subscribe(language => {
        this.onLanguageChangedByRecorder(language);
      }),
      this.correctorService.connectionState.subscribe(state =>
        this.onConnectionStateChanged(state)
      ),
      this.correctorService.recorderState.subscribe(state => (this.recorderState = state)),
      this.virtualScroll.elementScrolled().subscribe(event => {
        this.checkScrollButton();
      }),
      this.correctorService.manuallyInserted.subscribe(sentence => {
        this.onManuallyInserted(sentence);
      })
    );

    // Get current event url from route
    const eventUrl = this.route.snapshot.paramMap.get('url');

    // Load configuration
    try {
      this.globals.setIsLoading(true);

      // Auto-approve time
      const settings = await this.settingsService.getSettings();
      this.selectedAutoAppovalTimerDuration = settings.autoApprovalTimerDuration;
      this.correctorService.setAutoApproveTime(settings.autoApprovalTimerDuration);
      this.correctorService.isAutoApproval.next(settings.autoApprovalEnabled);

      // Languages
      this.allLanguages = await this.languageService.getAllAsync();
    } catch (error) {
      this.messageService.showReloadToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }

    // Load event and connect to corrector service
    try {
      this.globals.setIsLoading(true);
      this.event = await this.eventService.getEventAsync(eventUrl);
      await this.correctorService.connectAsync(this.event);
    } catch (error) {
      this.messageService.showReloadToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private onManuallyInserted(sentence: CorrectorSentence) {
    setTimeout(() => {
      const correctorSentenceElement = this.sentenceElements.find(
        x => x.sentence.id === sentence.id
      );
      if (correctorSentenceElement) {
        correctorSentenceElement.focus();
      }
    }, 100); // Wait, until element is rendered
  }

  ngOnDestroy() {
    this.correctorService.close();
    this.subscriptionManager.unsubscribeAll();
  }

  ngAfterViewInit() {
    this.resizeVirtualScroller();
    this.checkScrollButton();
  }

  ngAfterViewChecked() {
    // HACK: This method gets called A LOT - most times without an effect.
    // This is because sometimes, the virtual scroller only has a height of a few pixels.
    // This is currently not reliably reproducible. As a workaround, we resize the virtual scroller
    // one, after the whole DOM is rendered.
    if (!this.isInitialScrollerResizeDone) {
      const scrollerHeight = this.resizeVirtualScroller();
      if (scrollerHeight > 0) {
        console.log('Scroller properly resized.');
        this.isInitialScrollerResizeDone = true;
      }
    }
  }

  async onSelectedLanguageChanged(langages: Language[]) {
    if (this.event.isCorrectorLanguageChangeAllowed) {
      this.globals.setIsLoading(true);
      await this.correctorService.changeRecordingLanguageAsync(langages[0]);
      this.globals.setIsLoading(false);
    }
  }

  async onLanguageChanged() {
    if (this.event.isCorrectorLanguageChangeAllowed) {
      this.globals.setIsLoading(true);
      const language = await this.languageService.getByCodeAsync(this.event.language);
      await this.correctorService.changeRecordingLanguageAsync(language);
      this.globals.setIsLoading(false);
    }
  }

  private onSentencesChanged(sentences: CorrectorSentence[]) {
    this.sentences = sentences;
    this.scrollToBottomIfUnfocussed();
  }

  async autoApprovalTimeChanged() {
    this.correctorService.setAutoApproveTime(this.selectedAutoAppovalTimerDuration);

    // Save auto approve time to settings
    const settings = await this.settingsService.getSettings();
    settings.autoApprovalTimerDuration = this.selectedAutoAppovalTimerDuration;
    await this.settingsService.saveSettings(settings);
  }

  onApproved(sentence: CorrectorSentence) {
    this.correctorService.approve(sentence);
    this.focusSentenceAfter(sentence);
  }

  onRemoved(sentence: CorrectorSentence) {
    // Focus next sentence on removal, if there currently removed sentence was the focussed one.
    if (this.focussedSentence === sentence) {
      this.focusSentenceAfter(sentence);
    }

    this.correctorService.remove(sentence);
  }

  private focusSentenceAfter(sentence: CorrectorSentence) {
    const next = this.correctorService.getSentenceAfter(sentence);
    if (next) {
      const correctorSentenceElement = this.sentenceElements.find(x => x.sentence.id === next.id);
      if (correctorSentenceElement) {
        correctorSentenceElement.focus();
      }
    }
  }

  splitSentence() {
    if (this.focussedSentence) {
      const correctorSentenceElement = this.sentenceElements.find(
        x => x.sentence.id === this.focussedSentence.id
      );
      if (correctorSentenceElement) {
        correctorSentenceElement.splitAtCurrentPosition();
      }
    }
  }

  async onSplit(params: CorrectorSentenceSplitParams) {
    const text = params.sentence.text.substring(params.index);
    params.sentence.text = params.sentence.text.substring(0, params.index);
    await this.correctorService.insertSentenceAfter(params.sentence, text);
  }

  /**
   * Invokes the clear sentences service to notify listeners to clear
   */
  clearSentences() {
    if (this.event.isCorrectorClearSentencesAllowed) {
      this.correctorService.clearSentences();
    }
  }

  async onInsertedAfter(sentence: CorrectorSentence) {
    try {
      this.globals.setIsLoading(true);
      await this.correctorService.insertSentenceAfter(sentence);
    } catch (error) {
      this.messageService.showReloadToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  onFocussed(sentence: CorrectorSentence) {
    // Make sure, to save the currently focused sentence for hotkeys
    this.focussedSentence = sentence;
    this.correctorService.sentenceFocused(sentence);
  }

  onUnfocussed() {
    this.correctorService.sentenceUnfocused(this.focussedSentence);
    // No sentence is currently focused
    this.focussedSentence = undefined;
  }

  async onIsAutoApprovalChanged() {
    const settings = await this.settingsService.getSettings();
    settings.autoApprovalEnabled = this.isAutoApproval;
    await this.settingsService.saveSettings(settings);

    this.correctorService.isAutoApproval.next(this.isAutoApproval);
  }

  onLanguageChangedByRecorder(language: Language) {
    if (this.event.language !== language.code) {
      this.event.language = language.code;
      this.messageService.showToast(`Language has been changed to ${language.name}`);
    }
  }

  scrollToBottomIfUnfocussed(forced: boolean = false) {
    // Scroll down to the bottom, if a new sentence appears and no other sentence is currently
    // being edited
    if (forced || (this.autoScroll && !this.focussedSentence)) {
      setTimeout(() => {
        this.virtualScroll.scrollToIndex(this.virtualScroll.getDataLength() - 1, 'smooth');
      }, 300);
    } else {
      // Check, if we need to display the scroll down button
      setTimeout(() => {
        // HACK: To avoid ExpressionChangedAfterItHasBeenCheckedError
        this.checkScrollButton();
      });
    }
  }

  private checkScrollButton(): void {
    const isNearBottom = this.virtualScroll.measureScrollOffset('bottom') <= 40;
    if (isNearBottom) {
      this.autoScroll = true;
      this.isScrollDownButtonVisible = false;
    } else {
      this.autoScroll = false;
      this.isScrollDownButtonVisible = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeVirtualScroller();
  }

  resizeVirtualScroller(): number {
    console.log('Resizing virtual scroller...');
    const currentHeight = this.virtualScroll.elementRef.nativeElement.style.height;
    const newHeight = this.scrollParent.nativeElement.clientHeight;
    const newHeightPx = this.scrollParent.nativeElement.clientHeight + 'px';

    if (newHeightPx !== currentHeight) {
      this.virtualScroll.elementRef.nativeElement.style.height = newHeightPx;
      this.virtualScroll.checkViewportSize();
      console.log(`Virtual scroller height resized. Old: ${currentHeight}, New: ${newHeight}.`);
    } else {
      console.log('Virtual scroller height not changed.');
    }

    return newHeight;
  }

  private setupHotkeys() {
    // Approve hotkey
    this.hotkeysService.add(
      new Hotkey(
        ['command+return', 'ctrl+return'],
        (event: KeyboardEvent): boolean => {
          this.onApproved(this.focussedSentence);
          return false; // Prevent bubbling
        },
        ['textarea'],
        'Approve the selected sentence'
      )
    );

    // Remove hotkey
    this.hotkeysService.add(
      new Hotkey(
        ['command+u', 'ctrl+u'],
        (event: KeyboardEvent): boolean => {
          this.onRemoved(this.focussedSentence);
          return false; // Prevent bubbling
        },
        ['textarea'],
        'Remove the selected a sentence'
      )
    );

    // Insert hotkey
    this.hotkeysService.add(
      new Hotkey(
        ['command+i', 'ctrl+i', 'command+x', 'ctrl+x'],
        (event: KeyboardEvent): boolean => {
          this.onInsertedAfter(this.focussedSentence);
          return false; // Prevent bubbling
        },
        ['textarea'],
        'Insert a paragraph after the selected a sentence'
      )
    );

    // Clear hotkey
    this.hotkeysService.add(
      new Hotkey(
        ['command+shift+c', 'ctrl+shift+c'],
        (event: KeyboardEvent): boolean => {
          this.clearSentences();
          return false; // Prevent bubbling
        },
        ['textarea'],
        'Sends clear sentence to the listeners and removes output from listener window'
      )
    );

    // Split hotkey
    this.hotkeysService.add(
      new Hotkey(
        ['shift+enter'],
        (event: KeyboardEvent): boolean => {
          this.splitSentence();
          return false; // Prevent bubbling
        },
        ['textarea'],
        'Splits a sentence into two at the current position'
      )
    );
  }

  sentenceUpdated(sentence: CorrectorSentence): void {
    this.correctorService.sentenceUpdated(sentence);
  }

  public handleAudioStreamingTogglePress(): void {
    if (this.isStreamingAudio) {
      this.correctorService.unsubscribeFromAudioStreaming();
    } else {
      this.correctorService.subscribeToAudioStreaming();
    }
    this.isStreamingAudio = !this.isStreamingAudio;
  }

  private onConnectionStateChanged(state: ConnectionState): void {
    this.connectionState = state;
    this.isConnected = state === ConnectionState.Connected;
    this.isReconnecting = state === ConnectionState.Reconnecting;
    this.isDisconnected = state === ConnectionState.Disconnected;
    this.isConnecting =
      state === ConnectionState.Connecting || state === ConnectionState.Reconnecting;

    if (state === ConnectionState.Reconnecting) {
      this.messageService.showToast('Connection lost. Trying to reconnect...');
    }

    if (
      this.connectionState === ConnectionState.Reconnecting &&
      state === ConnectionState.Disconnected
    ) {
      this.messageService.showToast('Reconnecting to the server failed.');
    }

    if (
      this.connectionState === ConnectionState.Reconnecting &&
      state === ConnectionState.Connected
    ) {
      this.messageService.showToast('Reconnected to the server.');
    }
  }
}
