import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { Sentence } from '../../models/sentence';

@Injectable({
  providedIn: 'root'
})
export class SentenceService extends BaseApiService {
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super(authService);
  }

  async getId(): Promise<string> {
    try {
      const response = await this.httpClient
        .get<string>(environment.apiServiceUrl + '/api/sentence/id', {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to get a new sentence id.');
    }
  }

  async insertSentenceAfter(id: string): Promise<Sentence> {
    try {
      const response = await this.httpClient
        .get<Sentence>(environment.apiServiceUrl + `/api/sentence/insertSentenceAfter/${id}`, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to get the next sentence.');
    }
  }
}
