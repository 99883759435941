import { Directive, HostListener, EventEmitter, Output } from '@angular/core';
@Directive({
  selector: '[appDropZone]'
})
export class DropZoneDirective {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFileDropped = new EventEmitter<any>();

  constructor() {}

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }
}
