export function truncate(number: number, digits: number): number {
  const multiplier: number = Math.pow(10.0, digits);
  return Math.trunc(number * multiplier) / multiplier;
}

/**
 * Adding two double with a specific number of decimal places
 * Workaround for the issue that e.g. 6.01 + 0.00001
 * returns 6.010009999999999
 * @param number1
 * @param number2
 * @param digits
 */
export function add(number1: number, number2: number, digits: number): number {
  return +(number1 + number2).toFixed(digits);
}

export function hasDecimalPlaceBetween(
  number: number,
  decimalPlaceIndexStart: number,
  decimalPlaceIndexEnd: number
): boolean {
  const numberOfPlacesToCheck: number = decimalPlaceIndexEnd - decimalPlaceIndexStart + 1; // +1 because start should be included
  const truncatedNumber = truncate(number, decimalPlaceIndexEnd);
  const truncatedNumberAsString = truncatedNumber.toLocaleString(undefined, {
    maximumFractionDigits: decimalPlaceIndexEnd,
    minimumFractionDigits: decimalPlaceIndexEnd
  });
  return (
    parseInt(
      truncatedNumberAsString.substr(truncatedNumberAsString.length - numberOfPlacesToCheck),
      10
    ) !== 0
  );
}
