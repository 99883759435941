import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultDialogComponent } from '../dialogs/default.dialog.component';
import { DefaultDialogData } from '../dialogs/defaultDialogData';
import { DefaultDialogButton } from '../dialogs/defaultDialogButton';
import { SubscriptionManager } from '../helpers/subscriptionManager';
import { LoadingDialogOptions, LoadingDialogComponent } from '../dialogs/loading.dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  /**
   * Displays a toast message.
   * If another toast message is already displayed, it will be replaced by this one.
   * @param message The text to display
   * @param duration The duration after which the message is dismissed. Pass 0 for a permanent message
   */
  showToast(message: string, duration: number = 5000) {
    this.snackBar.open(message, null, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: duration
    });
  }

  /**
   * Displays a toast message with a reload button.
   * If another toast message is already displayed, it will be replaced by this one.
   * @param message The text to display
   * @param duration The duration after which the message is dismissed. Pass 0 for a permanent message
   */
  showReloadToast(message: string, duration: number = 5000) {
    const subscriptions = new SubscriptionManager();
    const snackBarRef = this.snackBar.open(message, 'Reload', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: duration
    });
    subscriptions.add(
      snackBarRef.onAction().subscribe({
        next: () => {
          location.reload();
        }
      })
    );

    subscriptions.add(
      snackBarRef.afterDismissed().subscribe({
        next: () => {
          subscriptions.unsubscribeAll();
        }
      })
    );
  }

  /**
   * Displays a toast message with a link button.
   * If another toast message is already displayed, it will be replaced by this one.
   * @param message The text to display
   * @param duration The duration after which the message is dismissed. Pass 0 for a permanent message
   */
  showLinkToast(message: string, buttonText: string, url: string) {
    const subscriptions = new SubscriptionManager();
    const snackBarRef = this.snackBar.open(message, buttonText, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 4000
    });

    subscriptions.add(
      snackBarRef.onAction().subscribe({
        next: () => {
          window.open(url);
        }
      })
    );

    subscriptions.add(
      snackBarRef.afterDismissed().subscribe({
        next: () => {
          subscriptions.unsubscribeAll();
        }
      })
    );
  }

  async showDialogAsync(title: string, text: string, cancel: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DefaultDialogComponent, {
        maxWidth: '55vw',
        data: new DefaultDialogData(title, text, new DefaultDialogButton(cancel, 0), [])
      });

      const subscription = dialogRef.afterClosed().subscribe({
        next: () => {
          subscription.unsubscribe();
          resolve();
        }
      });
    });
  }

  showNoConnectionDialog(): MatDialogRef<DefaultDialogComponent> {
    return this.dialog.open(DefaultDialogComponent, {
      maxWidth: '55vw',
      data: new DefaultDialogData(
        'Connection lost',
        'Whoops, it looks like you lost your connection to our servers. Can you please try to bring you back online?',
        new DefaultDialogButton('CLOSE', 0),
        []
      )
    });
  }

  async showActionDialogAsync(
    title: string,
    text: string,
    action: string,
    cancel: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DefaultDialogComponent, {
        maxWidth: '55vw',
        data: new DefaultDialogData(title, text, new DefaultDialogButton(action, 0), [
          new DefaultDialogButton(cancel, 1)
        ])
      });

      const subscription = dialogRef.afterClosed().subscribe({
        next: result => {
          subscription.unsubscribe();
          resolve(result === 0);
        }
      });
    });
  }

  async showMultiActionDialogAsync(
    title: string,
    text: string,
    buttons: string[]
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      // Add all buttons but the first to an array
      // First button is used as the default action button
      const dialogButtons: DefaultDialogButton[] = [];
      for (let i = 0; i < buttons.length; i++) {
        if (i !== 0) {
          dialogButtons.push(new DefaultDialogButton(buttons[i], i));
        }
      }

      const dialogRef = this.dialog.open(DefaultDialogComponent, {
        data: new DefaultDialogData(
          title,
          text,
          new DefaultDialogButton(buttons[0], 0),
          dialogButtons
        )
      });

      const subscription = dialogRef.afterClosed().subscribe({
        next: result => {
          subscription.unsubscribe();
          resolve(result);
        }
      });
    });
  }

  showLoadingDialog(
    title: string,
    message: string,
    isDisposable = false
  ): MatDialogRef<LoadingDialogComponent, any> {
    const options = new LoadingDialogOptions(title, message, isDisposable);
    const ref = this.dialog.open(LoadingDialogComponent, {
      disableClose: !isDisposable,
      data: options
    });

    return ref;
  }

  closeDialog(ref: MatDialogRef<any, any>) {
    ref.close();
  }
}
