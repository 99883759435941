import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { QrCodeData } from './qrCodeData';
import { EventService } from 'src/app/services/api/event.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  @Input() qrCodeData: QrCodeData;

  constructor(
    private route: ActivatedRoute,
    private globals: GlobalsService,
    private eventService: EventService,
    private messageService: MessageService,
    private elementRef: ElementRef
  ) {}

  async ngOnInit() {
    // If no QR Code Data is provided, try to get one from the current event url
    if (!this.qrCodeData) {
      try {
        this.globals.setIsLoading(true);
        const routeEventUrl = this.route.snapshot.paramMap.get('url');
        const event = await this.eventService.getEventAsync(routeEventUrl);
        this.qrCodeData = new QrCodeData(
          this.eventService.buildListenerUrlFor(event),
          this.eventService.buildQrCodeUrlFor(event)
        );
      } catch (error) {
        this.messageService.showToast(error.message);
      } finally {
        this.globals.setIsLoading(false);
      }
    }
  }
}
