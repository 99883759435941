import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ZoomPluginConfiguration from '../models/zoomPluginConfiguration';
import { ZoomUpdateMode } from '../enums/zoomUpdateMode';
import { MessageService } from '../services/message.service';
import { isUrl } from '../helpers/stringHelpers';

@Component({
  selector: 'app-zoom-plugin-dialog',
  templateUrl: 'zoom-plugin.dialog.component.html'
})
export class ZoomPluginDialogComponent {
  config = new ZoomPluginConfiguration();
  updateModes = [
    // {
    //   name: 'Send Text immediately',
    //   shortDescription: 'Zoom Transcript will contain duplicates',
    //   longDescription:
    //     'Subtitles will be sent immediately but the Zoom Transcript will become unusable as it contains a lot of duplicates.',
    //   zoomUpdateMode: ZoomUpdateMode.Immediately
    // },
    {
      name: 'Send full sentences',
      shortDescription: 'Zoom Transcript will remain usable',
      longDescription:
        'Subtitles will be delayed until a full sentence is available. The Zoom Transcript remains usable.',
      zoomUpdateMode: ZoomUpdateMode.FullSentence
    }
  ];
  isExisting: boolean;

  constructor(
    public dialogRef: MatDialogRef<ZoomPluginDialogComponent, ZoomPluginDialogResult>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: ZoomPluginConfiguration
  ) {
    Object.assign(this.config, data);
    //this.config = data;
    this.isExisting = this.config.apiToken.trim().length > 0;
  }

  submit(): void {
    if (this.config.apiToken.trim().length === 0) {
      this.messageService.showToast('Zoom API Token can not be empty.');
      return;
    }

    if (!isUrl(this.config.apiToken.trim())) {
      this.messageService.showToast('Zoom API Token must be a valid URL.');
      return;
    }

    this.dialogRef.close({ config: this.config });
  }
}

export class ZoomPluginDialogResult {
  config: ZoomPluginConfiguration;
}
