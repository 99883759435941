import { Sentence } from '../models/sentence';
import { DisplaySentence } from '../models/displaySentence';
import { truncate, add } from './numberHelpers';

export function moveOrderNumberIfNecessary(
  sentences: Sentence[] | DisplaySentence[],
  newSentence: Sentence
): void {
  // if the new sentence is not an inserted one => nothing to do
  if (!newSentence.isInsertedSentence) {
    return;
  }
  // if the new inserted sentence is already part of the sentence => moving is already done
  // => nothing to do
  if (sentences.find(x => x.id === newSentence.id)) {
    return;
  }
  // move the existing inserted sentences by incrementing the order
  sentences
    // only all inserted sentence of the regular sentence
    .filter(
      x =>
        regularSentenceOrder(x.order) === regularSentenceOrder(newSentence.order) &&
        x.isInsertedSentence
    )
    // all which are affected by the moving
    .filter(x => x.order >= newSentence.order)
    // move them by 1
    .forEach(x => (x.order = add(x.order, 0.00001, 5)));
}

/**
 * Sorts a sentence array by order, then creation
 * @param sentences An array of sentences to order
 */
export function sort(sentences: Sentence[] | DisplaySentence[]) {
  sentences.sort(function(a, b) {
    // Check order first
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    // If orders are equal, sort by creation
    if (a.order === b.order) {
      if (a.creation < b.creation) {
        return -1;
      }
      if (a.creation > b.creation) {
        return 1;
      }
    }
    return 0;
  });
}

/**
 *  Returns the order of the regular sentence which belongs to this sentence
 *  For inserted sentence this can be the order of a main sentence or a sub sentence
 *  For main- or sub sentence this own order will be returned
 */
export function regularSentenceOrder(order: number): number {
  return truncate(order, 2);
}
