import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/services/message.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '../helpers/subscriptionManager';
import { AdminService } from '../services/api/admin.service';

@Component({
  selector: 'app-booked-minutes-dialog',
  templateUrl: 'booked-minutes.dialog.component.html'
})
export class BookedMinutesDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  subscription: Subscription;
  bookedMinutes: number;

  private subscriptionManager = new SubscriptionManager();

  constructor(
    public globals: GlobalsService,
    public dialogRef: MatDialogRef<BookedMinutesDialogComponent>,
    private messageService: MessageService,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.bookedMinutes = data.bookedMinutes;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));
  }

  async submit() {
    this.globals.setIsLoading(true);

    try {
      await this.adminService.updateBookedMinutesForUserAsync(this.data.userId, this.bookedMinutes);

      this.globals.setIsLoading(false);
      this.dialogRef.close();
      this.messageService.showToast(`Updated booked duration to ${this.bookedMinutes} minutes`);
    } catch (error) {
      this.globals.setIsLoading(false);
      console.log('Error!', error);
      this.messageService.showToast('Unable update booked duration!');
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }
}
