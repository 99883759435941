import { ElementRef, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Sentence } from '../models/sentence';
import { BehaviorSubject, Subject } from 'rxjs';
import { Event } from '../models/event';
import {
  HubConnectionBuilder,
  HubConnection,
  HubConnectionState,
  LogLevel,
  HttpTransportType
} from '@microsoft/signalr';
import { AuthService } from './auth.service';
import { BackendMessage } from '../models/backendMessage';
import { Language } from '../models/language';
import { sort } from '../helpers/sentenceHelpers';
import { ConnectionState, ConnectionStateUtils } from '../helpers/connectionState';
import LocalAudioRecordingService from './localAudioRecordingService.service';
import { SubscriptionManager } from '../helpers/subscriptionManager';
import { RecorderState, RecordingStatus } from '../models/recorderState';
import { SignalRRetryPolicy } from '../helpers/signalRRetryPolicy';

@Injectable({
  providedIn: 'root'
})
export class RecorderService {
  private hubConnection: HubConnection;
  private stopWatchTimer: any;
  private sentences: Sentence[] = [];
  private currentLanguage: Language;
  private localAudioRecordingService: LocalAudioRecordingService;

  // Manage all subscriptions
  private subscriptionManager = new SubscriptionManager();

  // Observables
  behaviorSentences: BehaviorSubject<Sentence[]> = new BehaviorSubject<Sentence[]>(this.sentences);
  state: BehaviorSubject<RecorderState> = new BehaviorSubject<RecorderState>(new RecorderState());
  sessionDurationInSeconds: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  messageReceived = new Subject<BackendMessage>();
  languageChangedByCorrector = new Subject<Language>();
  connectionState: BehaviorSubject<ConnectionState> = new BehaviorSubject<ConnectionState>(
    ConnectionState.Disconnected
  );
  isAudioMonitorOn = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {}

  /**
   * Connects to the SignalR Hub and prepares the connections to the Speech Service and Stream.
   * This method has to be called once, before 'start' and 'stop' can be called.
   * @param event The event to connect with
   */
  async connectAsync(event: Event, animationCanvas: ElementRef<HTMLCanvasElement>) {
    this.clearSentences();

    // Prepare local audio recording
    this.localAudioRecordingService = new LocalAudioRecordingService(animationCanvas);
    this.subscriptionManager.add(
      this.localAudioRecordingService.chunkReceived.subscribe(
        async chunk => await this.onAudioChunkReceivedAsync(chunk)
      ),
      this.localAudioRecordingService.isAudioMonitorOn.subscribe(value => this.isAudioMonitorOn.next(value))
    );

    // Prepare SignalR connection to the Streaming Backend
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.streamingServiceUrl}/recorder?eventId=${event.id}`, {
        accessTokenFactory: async () => await this.authService.getAccessTokenAsync()
      })
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect(new SignalRRetryPolicy())
      .build();

    // The server sends a message to the clients at least every 3s. It is recommended to consider
    // a connection as disrupted, when not receiving a message after this time x2.
    this.hubConnection.serverTimeoutInMilliseconds = 6000;
    this.hubConnection.keepAliveIntervalInMilliseconds = 3000;

    // React on socket state changes
    this.hubConnection.onreconnecting(err => this.onReconnecting());
    this.hubConnection.onreconnected(async id => await this.onReconnectedAsync());
    this.hubConnection.onclose(err => this.onClose());

    // Subscribe to events from stream
    this.hubConnection.on('ReceivedSentence', (sentence: Sentence) => this.onSentenceReceived(sentence));
    this.hubConnection.on('ReceivedMessage', (message: BackendMessage) => this.onBackendMessageReceived(message));
    this.hubConnection.on('ChangedLanguage', async (language: Language) => await this.onLanguageChangedAsync(language));
    this.hubConnection.on('StoppedRecording', async () => await this.onServerStoppedRecordingAsync());
    this.hubConnection.on('StartedRecording', async () => await this.onServerStartedRecordingAsync());
    this.hubConnection.on('StateChanged', async (state: RecorderState) => await this.onStateChangedAsync(state));

    // Start streaming connection
    await this.hubConnection.start();
    this.connectionState.next(ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state));
  }

  /**
   * Starts the recording using the default microphone input and connects to the stream.
   */
  async startAsync(): Promise<void> {
    if (this.hubConnection.state !== HubConnectionState.Connected) {
      throw new Error('Cannot start the recording. Client is not connected.');
    }

    if (!this.state.value.isReady) {
      throw new Error('Cannot start the recording. Client is not ready.');
    }

    // Send a message to the server to prepare for a recording. Once the server sends a
    // 'RecordingStarted' event back, we can start the local recording and send audio chunks.
    await this.startServerRecordingAsync();
  }

  /**
   * Stops the current recording but not the connection to the WebSocket.
   */
  async stopAsync() {
    await this.stopLocalRecordingAsync();
    await this.stopServerRecordingAsync();
  }

  /**
   * Stops the connection to the WebSocket
   */
  async closeAsync() {
    await this.hubConnection.stop();
    await this.stopLocalRecordingAsync();
    this.subscriptionManager.unsubscribeAll();
  }

  async clearListenersAsync(): Promise<void> {
    this.clearSentences();
    this.hubConnection.send('ClearSentences');
  }

  async setLanguageAsync(language: Language) {
    if (this.currentLanguage === language) {
      return;
    }

    this.currentLanguage = language;

    // Inform the backend about the language change
    this.hubConnection.send('ChangeLanguage', language);
  }

  async addDemoSentenceAsync(long: boolean): Promise<void> {
    await this.hubConnection.send('AddDemoSentence', long);
  }

  async addLongTestSentenceAsync(addDelay: boolean): Promise<void> {
    await this.hubConnection.send('AddLongTestSentence', addDelay);
  }

  toggleAudioMonitor(): void {
    this.localAudioRecordingService.toggleAudioMonitor();
  }

  private clearSentences(): void {
    this.sentences.length = 0;
    this.behaviorSentences.next(this.sentences);
  }

  private async startServerRecordingAsync(): Promise<any> {
    await this.hubConnection.send('StartRecording');
  }

  private async startLocalRecordingAsync() {
    if (!this.localAudioRecordingService.isRecording) {
      await this.localAudioRecordingService.startAsync();
    }

    this.startStopwatch();
  }

  private async stopServerRecordingAsync(): Promise<any> {
    await this.hubConnection.send('StopRecording');
  }

  /**
   * Stops the recording locally but not the timers on the server. Should only be used with the
   * intention to immediately start the recording afterwards.
   */
  private async stopLocalRecordingAsync(): Promise<void> {
    await this.localAudioRecordingService.stopAsync();
    this.stopStopwatch();
  }

  private stopStopwatch(): void {
    clearInterval(this.stopWatchTimer);
  }

  private startStopwatch(): void {
    clearInterval(this.stopWatchTimer);
    this.stopWatchTimer = setInterval(() => {
      this.sessionDurationInSeconds.next(this.sessionDurationInSeconds.value + 1);
    }, 1000); // 1 sec
  }

  private async onAudioChunkReceivedAsync(chunk: ArrayBuffer) {
    // Drop audio during language switches
    if (this.state.value.isSwitchingLanguage) {
      return;
    }

    if (
      this.connectionState.value === ConnectionState.Connected &&
      this.state.value.recordingStatus === RecordingStatus.Recording
    ) {
      await this.sendAudioChunkToServerAsync(chunk);
    }
  }

  private async sendAudioChunkToServerAsync(chunk: ArrayBuffer) {
    const uInt8Array = new Uint8Array(chunk);
    const b64encoded = btoa(String.fromCharCode.apply(null, uInt8Array));
    await this.hubConnection.send('SendAudioChunk', b64encoded);
  }

  private onBackendMessageReceived(message: BackendMessage): void {
    this.messageReceived.next(message);
  }

  private onSentenceReceived(sentence: Sentence): void {
    this.addSentence(sentence);
  }

  /**
   * Adds a new sentence to the array or replaces an existing one
   */
  private addSentence(sentence: Sentence) {
    // Check, if sentence was already in the sentence array
    const existingSentence = this.sentences.find(x => x.id === sentence.id);
    if (existingSentence) {
      // Existing sentence found. Update the existing sentence
      const index = this.sentences.indexOf(existingSentence);
      this.sentences[index] = sentence;
    } else {
      // No existing sentence found. Add it as a new one
      this.sentences.push(sentence);
    }

    // Sort sentences by order, then id
    sort(this.sentences);

    // Update observable version of sentences
    this.behaviorSentences.next(this.sentences);
  }

  // Connection management
  private onReconnecting(): void {
    console.log('Connection to the server lost. reconnecting...');
    this.stopStopwatch();
    this.connectionState.next(ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state));
  }

  private async onReconnectedAsync(): Promise<void> {
    console.log('Reconnected.');
    this.connectionState.next(ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state));

    // Re-start server recording, if it was running previously
    if (this.localAudioRecordingService.isRecording) {
      await this.startServerRecordingAsync();
    }
  }

  private async onStateChangedAsync(state: RecorderState): Promise<void> {
    this.state.next(state);
  }

  private onClose(): void {
    console.log('WebSocket connection to Streaming Service has been closed.');
    this.stopLocalRecordingAsync();
    this.connectionState.next(ConnectionStateUtils.fromHubConnectionState(this.hubConnection.state));

    this.state.value.isReady = false;
    this.state.next(this.state.value);
  }

  private async onLanguageChangedAsync(language: Language): Promise<void> {
    this.languageChangedByCorrector.next(language);
    await this.setLanguageAsync(language);
  }

  private async onServerStoppedRecordingAsync(): Promise<void> {
    console.log('The Server stopped the recording.');
    await this.stopLocalRecordingAsync();
  }

  private async onServerStartedRecordingAsync(): Promise<void> {
    console.log('The Server started the recording.');
    await this.startLocalRecordingAsync();
  }
}
