import { Component, OnInit } from '@angular/core';
import { AuthProviderType } from 'src/app/enums/authProviderType';
import launchContext from 'src/app/models/launchContext';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isLoginButtonVisible = true;
  isAuth0Enabled = false;
  isAadEnabled = false;

  constructor(private authService: AuthService) {
    this.isAuth0Enabled = environment.auth0Enabled;
    this.isAadEnabled = environment.aadEnabled;

    // Hide Login button in Microsoft Teams, as we use SSO here.
    if (launchContext.isTeamsMode) {
      this.isLoginButtonVisible = false;
    }
  }

  loginWithEmail() {
    this.authService.login(AuthProviderType.Auth0);
  }

  loginWithAad() {
    this.authService.login(AuthProviderType.AzureActiveDirectory);
  }
}
