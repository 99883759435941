import { Component, OnInit, HostListener, AfterViewChecked } from '@angular/core';
import { EventService } from 'src/app/services/api/event.service';
import { MessageService } from 'src/app/services/message.service';
import { Event } from 'src/app/models/event';
import { GlobalsService } from 'src/app/services/globals.service';
import { AuthService } from 'src/app/services/auth.service';
import { VocabularyBook } from 'src/app/models/vocabularyBook';
import { VocabularyBookService } from 'src/app/services/api/vocabulary-book.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewChecked {
  events: Event[];
  books: VocabularyBook[];
  isGrid: boolean;
  orderBy: string;
  selectedTabIndex = 0;

  constructor(
    public globals: GlobalsService,
    private eventService: EventService,
    private vocabularyBookService: VocabularyBookService,
    private messageService: MessageService,
    private authService: AuthService,
    private logger: LoggerService
  ) {
    this.events = [];
    this.books = [];
    this.isGrid = JSON.parse(localStorage.getItem('isGrid'));
    if (this.isGrid === null) {
      this.isGrid = true;
    }
    this.orderBy = localStorage.getItem('orderBy');
    if (this.orderBy === null) {
      this.orderBy = '-createdAt';
    }

    this.selectedTabIndex = JSON.parse(localStorage.getItem('selectedTabIndex')) || 0;
  }

  async ngOnInit() {
    try {
      this.globals.setIsLoading(true);
      this.events = await this.eventService.getEventsAsync();
      this.books = await this.vocabularyBookService.getBooksAsync();
    } catch (error) {
      // Only show message, if user is logged in
      if (this.authService.isLoggedIn.value === true) {
        this.messageService.showToast(error.message);
      }
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  ngAfterViewChecked() {
    this.squares();
  }

  showAsGrid(value: boolean) {
    this.isGrid = value;
    localStorage.setItem('isGrid', JSON.stringify(value));
  }

  onSelectedIndexChanged(index: number) {
    this.selectedTabIndex = index;
    localStorage.setItem('selectedTabIndex', JSON.stringify(index));
  }

  onOrderByChanged() {
    this.orderBy = this.orderBy;
    localStorage.setItem('orderBy', this.orderBy);
  }

  squares() {
    // Get all elements with square class
    const elements = document.getElementsByClassName('square');
    for (let i = 0; i < elements.length; i++) {
      const e = elements[i];
      if (e instanceof HTMLElement) {
        // Check, if with is higher than Bootstrap's Medium breakpoint, because if the
        // view with is below that, we only display a list
        // Medium breakpoint is 768px according to: https://getbootstrap.com/docs/4.1/layout/grid/#grid-options
        if (document.documentElement.clientWidth >= 768 && this.isGrid) {
          e.style.height = e.clientWidth + 'px';
        } else {
          e.style.height = 'auto';
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.squares();
  }
}
