import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrCodeData } from 'src/app/components/qrcode/qrCodeData';
import { MessageService } from 'src/app/services/message.service';
import * as copy from 'copy-to-clipboard';

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: 'qrcode.dialog.component.html'
})
export class QrCodeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: QrCodeData
  ) {}

  public copyToClipboard() {
    if (copy(this.data.qrUrl, { format: 'text/plain' })) {
      this.messageService.showToast('Copied url to clipboard');
    }
  }
}
