import { AuthService } from '../auth.service';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationFailedError } from 'src/app/errors/authentication-failed-error';

export abstract class BaseApiService {
  constructor(private baseAuthService: AuthService) {}

  protected async getHeadersAsync(): Promise<HttpHeaders> {
    let accessToken = '';
    if (this.baseAuthService.isLoggedIn.value) {
      accessToken = await this.baseAuthService.getAccessTokenAsync();
    }

    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken);
  }

  protected handleHttpError(error: Error, defaultText: string = 'An error occurred.') {
    if (error instanceof HttpErrorResponse) {
      throw new Error(error.error);
    } else {
      throw new Error(defaultText);
    }
  }
}
