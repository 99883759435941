import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-color-picker',
  templateUrl: './form-color-picker.component.html',
  styleUrls: ['./form-color-picker.component.scss']
})
export class FormColorPickerComponent implements OnInit {
  @Input() placeholder = '';
  @Input() transparent = false;
  @Input() color = '#000000';
  @Output() colorChange = new EventEmitter<string>();

  defaultColors: string[] = ['#000000', '#FFFFFF', '#333333', '#CCCCCC'];
  lastColor: string;

  constructor() {
    this.lastColor = this.color;
  }

  ngOnInit() {}

  onColorInputBlurred(event: any) {
    if (this.validateColor(event.target.value)) {
      this.lastColor = event.target.value;
      this.colorChange.emit(this.color);
    } else {
      event.target.value = this.lastColor;
      this.color = this.lastColor;
    }
  }

  onColorSelected(event: any) {
    if (this.validateColor(event)) {
      this.lastColor = event;
      this.colorChange.emit(this.color);
    } else {
      event = this.lastColor;
      this.color = this.lastColor;
    }
  }

  validateColor(color: string): boolean {
    const regex =
      this.transparent === true
        ? /^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{3})$/gm
        : /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/gm;
    return regex.test(color);
  }
}
