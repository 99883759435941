export class BackendMessage {
  type: BackendMessageType;
  details: string;
}

export enum BackendMessageType {
  BookedTimeExceeded = 'BookedTimeExceeded',
  WrongEventPassword = 'WrongEventPassword',
  CorrectEventPassword = 'CorrectEventPassword',
  NoCorrectorConnected = 'NoCorrectorConnected',
  CorrectorNotApproving = 'CorrectorNotApproving',
  MultipleRecordersConnected = 'MultipleRecordersConnected',
  RecordingLanguageChanged = 'RecordingLanguageChanged',
  RecordingStarted = 'RecordingStarted',
  RecordingNeedsToBeStarted = 'RecordingNeedsToBeStarted',
  SentenceCanNotBeApproved = 'SentenceCanNotBeApproved',
  InternalError = 'InternalError',
  NotAllowed = 'NotAllowed'
}
