import { Injectable } from '@angular/core';
import { Event } from '../models/event';
import { EventService } from './api/event.service';
import { saveAs } from 'file-saver';
import { LanguageService } from './api/language.service';
import { TranscriptDownloadRequest, TranscriptFormat } from '../models/transcriptDownloadRequest';

@Injectable({
  providedIn: 'root'
})
export class TranscripService {
  constructor(private eventService: EventService) {}

  async downloadAsync(event: Event, request: TranscriptDownloadRequest) {
    const content = await this.eventService.getTranscriptAsync(event.id, request);
    const filename = `${event.name}.${request.format.toLocaleLowerCase()}`;
    const blob = new Blob([content], {
      type: this.getType(request.format)
    });
    saveAs(blob, filename);
  }

  getType(format: TranscriptFormat): string {
    switch (format) {
      default:
      case TranscriptFormat.TXT:
        return 'text/plain';
      case TranscriptFormat.SRT:
        return 'text/srt';
      case TranscriptFormat.VTT:
        return 'text/vtt';
      case TranscriptFormat.PDF:
        return 'application/pdf';
    }
  }
}
