export class Language {
  name: string;
  code: string;
  languageName: string;
  regionName: string;
  languageCode: string;
  regionCode: string;
  direction: string;
  isAvailableForRecording: boolean;
  isAvailableForTranslation: boolean;
  isAvailableForVocabularyBook: boolean;
  specialFont: string;
  translationLanguageCode: string;

  static getOriginal(): Language {
    const original = new Language();
    original.name = 'Original';
    original.languageCode = '';
    original.translationLanguageCode = '';
    return original;
  }
}
