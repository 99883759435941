import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventService } from 'src/app/services/api/event.service';
import { MessageService } from 'src/app/services/message.service';
import { Event } from 'src/app/models/event';
import { GlobalsService } from 'src/app/services/globals.service';
import { VocabularyBookService } from 'src/app/services/api/vocabulary-book.service';
import { VocabularyBook } from 'src/app/models/vocabularyBook';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';

@Component({
  selector: 'app-delete-event',
  templateUrl: './delete-event.component.html',
  styleUrls: ['./delete-event.component.scss']
})
export class DeleteEventComponent implements OnInit, OnDestroy {
  events: Event[];
  vocabularyBooks: VocabularyBook[];
  isLoading = false;

  private subscriptionManager = new SubscriptionManager();

  constructor(
    public globals: GlobalsService,
    private eventService: EventService,
    private vocabularyBookService: VocabularyBookService,
    private messageService: MessageService
  ) {
    this.events = [];
    this.vocabularyBooks = [];
  }

  async ngOnInit() {
    try {
      this.globals.setIsLoading(true);
      await this.refreshItems();
    } finally {
      this.globals.setIsLoading(false);
    }

    this.subscriptionManager.add(
      this.globals.getIsLoading().subscribe(value => (this.isLoading = value))
    );
  }

  async ngOnDestroy() {
    this.subscriptionManager.unsubscribeAll();
  }

  private async refreshItems() {
    this.events = (await this.eventService.getEventsAsync(true)).filter(
      event => event.isDeleted === true
    );

    this.vocabularyBooks = (await this.vocabularyBookService.getBooksAsync(true)).filter(
      book => book.isDeleted === true
    );
  }

  async deleteEvent(toBeDeletedEvent: Event) {
    const result = await this.messageService.showActionDialogAsync(
      'Delete event permanently',
      'Are you sure, that you want to permanently delete the event? This can not be undone!',
      'Delete permanently',
      'Cancel'
    );

    if (result === true) {
      try {
        this.globals.setIsLoading(true);
        await this.eventService.deleteEventAsync(toBeDeletedEvent);
        await this.refreshItems();
      } catch (error) {
        this.messageService.showToast(error.message);
      } finally {
        this.globals.setIsLoading(false);
      }
    }
  }

  async restoreEvent(event: Event) {
    try {
      this.globals.setIsLoading(true);
      event.isDeleted = false;
      await this.eventService.changeEventAsync(event);
      await this.refreshItems();
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async deleteBook(book: VocabularyBook) {
    const result = await this.messageService.showActionDialogAsync(
      'Delete vocabulary book permanently',
      'Are you sure, that you want to permanently delete the vocabulary book? This can not be undone!',
      'Delete permanently',
      'Cancel'
    );

    if (result === true) {
      try {
        this.globals.setIsLoading(true);
        await this.vocabularyBookService.deleteBookAsync(book);
        await this.refreshItems();
      } catch (error) {
        this.messageService.showToast(error.message);
      } finally {
        this.globals.setIsLoading(false);
      }
    }
  }

  async restoreBook(book: VocabularyBook) {
    try {
      this.globals.setIsLoading(true);
      book.isDeleted = false;
      await this.vocabularyBookService.changeBookAsync(book);
      await this.refreshItems();
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }
}
