import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { LangageSelectionDialogComponent } from 'src/app/dialogs/language-selection.dialog.component';
import { Language } from 'src/app/models/language';
import { LanguageService } from 'src/app/services/api/language.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnChanges {
  @Input() selectedLanguageCodes: string[] = [];
  @Input() selectedLanguageCode = '';
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() showBookAvailability = true;
  @Input() languageSet = 'recording';
  @Input() vocabularyBookLanguagesOnly = false;

  @Output() change = new EventEmitter<Language[]>();
  selectedLanguages: Language[] = [];
  selectedLanguageText = '';

  private availableLanguages: Language[] = [];

  constructor(public dialog: MatDialog, public globals: GlobalsService, private languageService: LanguageService) {}

  async ngOnInit() {
    switch (this.languageSet) {
      default:
      case 'recording':
        this.availableLanguages = await this.languageService.getRecordingLanguagesAsync();
        break;
      case 'translation':
        this.availableLanguages = await this.languageService.getTranslationLanguagesAsync();
        break;
      case 'vocabularyBook':
        this.availableLanguages = await this.languageService.getVocabularyBookLanguagesAsync();
        break;
    }

    this.updateSelection();
  }

  ngOnChanges() {
    this.updateSelection();
  }

  openDialog(): void {
    const dialog = this.dialog.open(LangageSelectionDialogComponent, {
      data: {
        availableLanguages: this.availableLanguages,
        selectedLanguages: this.selectedLanguages,
        showBookAvailability: this.showBookAvailability,
        multiple: this.multiple
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.updateSelectedLanguageText(result);
        this.change.emit(result);
      }
    });
  }

  private updateSelection() {
    this.selectedLanguages = this.multiple
      ? this.availableLanguages.filter(x => _.includes(this.selectedLanguageCodes, x.code))
      : this.availableLanguages.filter(x => x.code === this.selectedLanguageCode);
    this.updateSelectedLanguageText(this.selectedLanguages);
  }

  private updateSelectedLanguageText(languages: Language[]) {
    if (languages.length > 0) {
      this.selectedLanguageText = languages.map(x => x.name).join(', ');
    } else {
      this.selectedLanguageText = this.multiple ? 'Select languages' : 'Select a language';
    }
  }
}
