import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadable',
})
export class HumanReadablePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value === 'VocabularyBook') {
      return 'Vocabulary Book';
    }

    return value;
  }
}
