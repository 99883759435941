import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Language } from '../models/language';
import { Event } from '../models/event';
import { TranscriptDownloadRequest, TranscriptFormat } from '../models/transcriptDownloadRequest';
import * as copy from 'copy-to-clipboard';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-url-parameters-dialog',
  templateUrl: 'url-parameters.dialog.component.html'
})
export class UrlParametersDialogComponent {
  parameters: string;

  constructor(
    public dialogRef: MatDialogRef<UrlParametersDialogComponent>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.parameters = data;
  }

  copy(): void {
    copy(this.parameters, { format: 'text/plain' });
    this.messageService.showToast('Copied URL parameters to clipboard.');
  }
}

export class TranscriptDownloadDialogData {
  event: Event;
  languages: Language[];
}
