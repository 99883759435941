import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class LoadingDialogOptions {
  constructor(public title: string, public message: string, public isDisposable: boolean) {}
}

@Component({
  selector: 'app-loading-dialog',
  templateUrl: 'loading.dialog.component.html',
})
export class LoadingDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadingDialogOptions
  ) {}
}
