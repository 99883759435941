import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { AuthService } from '../auth.service';
import { BaseApiService } from './base-api.service';
import { GlobalsService } from '../globals.service';
import { Beta } from '../../models/beta';
import { LocalCache } from '../../helpers/localCache';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {
  private cachedUsers: LocalCache<User>;
  private currentUserId: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private globals: GlobalsService
  ) {
    super(authService);
    this.cachedUsers = new LocalCache<User>();
  }

  async getCurrentUserAsync(): Promise<User> {
    // Try to return from local cache
    const cachedUser = this.cachedUsers.getItem(x => x.userId === this.currentUserId);
    if (cachedUser) {
      return cachedUser;
    }

    try {
      const user = await this.httpClient
        .get<User>(environment.apiServiceUrl + '/api/user/', {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      if (user.beta) {
        this.globals.beta = user.beta || new Beta();
      }

      // Add to local cache
      this.cachedUsers.addItem(user);
      this.currentUserId = user.userId;

      return user;
    } catch (error) {
      throw new Error('Could not get current user.');
    }
  }

  async updateUserAsync(user: User): Promise<User> {
    try {
      const updatedUser = await this.httpClient
        .put<User>(environment.apiServiceUrl + '/api/user/' + user.userId, user, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Update local cache
      this.cachedUsers.updateItem(x => x.userId === user.userId, updatedUser);

      return updatedUser;
    } catch (error) {
      throw new Error('Could not save current user.');
    }
  }
}
