export class Settings {
  fontFamily: string;
  fontSize: string;
  fontColor: string;
  fontTransform: string;
  backgroundColor: string;
  useOutlineColor: boolean;
  outlineColor: string;
  /**
   * The duration of the auto approval timer in seconds
   */
  autoApprovalTimerDuration: number;
  autoApprovalEnabled: boolean;
  // Flag to track through app insights
  allowTracking: boolean;

  public get textShadow(): string {
    if (!this.useOutlineColor) {
      return '';
    }
    return `0 0 5px ${this.outlineColor}`;
  }

  constructor(private settings?: Settings) {
    // Default settings, that are used when no user is logged in.
    // This can be the case, when users anonymously receive subtitles and
    // want to change display settings without creating an account or logging in.
    if (undefined === settings) {
      this.setDefaultValues();
    } else {
      this.setDefaultValues(
        settings.fontFamily,
        settings.fontSize,
        settings.fontColor,
        settings.fontTransform,
        settings.backgroundColor,
        settings.autoApprovalTimerDuration,
        settings.autoApprovalEnabled,
        settings.allowTracking,
        settings.useOutlineColor,
        settings.outlineColor
      );
    }
  }

  setDefaultValues(
    fontFamily = 'Arial',
    fontSize = '36px',
    fontColor = '#ffffff',
    fontTransform = 'lowercase',
    backgroundColor = '#000000',
    autoApprovalTimerDuration = 20,
    autoApprovalEnabled = true,
    allowTracking = false,
    useOutlineColor = false,
    outlineColor = '#000000'
  ) {
    this.fontFamily = fontFamily;
    this.fontSize = fontSize;
    this.fontColor = fontColor;
    this.fontTransform = fontTransform;
    this.backgroundColor = backgroundColor;
    this.autoApprovalTimerDuration = autoApprovalTimerDuration;
    this.autoApprovalEnabled = autoApprovalEnabled;
    this.allowTracking = allowTracking;
    this.useOutlineColor = useOutlineColor;
    this.outlineColor = outlineColor;
  }

  override(
    fontFamily: string,
    fontSize: string,
    fontColor: string,
    fontTransform: string,
    backgroundColor: string,
    useOutlineColor: boolean,
    outlineColor: string
  ) {
    this.fontFamily = fontFamily ? fontFamily : this.fontFamily;
    this.fontSize = fontSize ? fontSize : this.fontSize;
    this.fontColor = fontColor ? fontColor : this.fontColor;
    this.fontTransform = fontTransform ? fontTransform : this.fontTransform;
    this.backgroundColor = backgroundColor ? backgroundColor : this.backgroundColor;
    this.useOutlineColor = useOutlineColor ? useOutlineColor : this.useOutlineColor;
    this.outlineColor = outlineColor ? outlineColor : this.outlineColor;
  }

  generateUrlParams(): string {
    let params =
      `?fontFamily=${encodeURIComponent(this.fontFamily)}&` +
      `fontSize=${encodeURIComponent(this.fontSize)}&` +
      `fontColor=${encodeURIComponent(this.fontColor)}&` +
      `fontTransform=${encodeURIComponent(this.fontTransform)}&` +
      `backgroundColor=${encodeURIComponent(this.backgroundColor)}`;

    if (this.useOutlineColor) {
      params +=
        `&useOutlineColor=${encodeURIComponent(this.useOutlineColor.toString())}&` +
        `outlineColor=${encodeURIComponent(this.outlineColor)}`;
    }

    return params;
  }
}
