import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { VocabularyBook } from '../models/vocabularyBook';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService {
  constructor() {}

  public exportVocabularyBookWords(book: VocabularyBook): void {
    const content = book.words.map(word => word).join('\n');
    const filename = `${book.name}.txt`;
    const blob = new Blob([content], {
      type: 'text/plain'
    });
    saveAs(blob, filename);
  }

  public async importVocabularyBookWords(book: VocabularyBook): Promise<VocabularyBook> {
    // Create and open a file picker
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.accept = 'text/plain';

    return new Promise<VocabularyBook>(resolve => {
      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const content = reader.result as string;
          const words = content.split('\n');
          words.forEach(word => {
            if (book.words.indexOf(word) === -1) {
              book.words.push(word);
            }
          });

          resolve(book);
        };
        reader.readAsText(file);
      };

      input.click();
    });
  }
}
