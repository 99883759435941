import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CorrectorSentence } from 'src/app/models/correctorSentence';
import CorrectorSentenceSplitParams from './correctorSentenceSplitParams';

@Component({
  selector: 'app-corrector-sentence',
  templateUrl: './corrector-sentence.component.html',
  styleUrls: ['./corrector-sentence.component.scss']
})
export class CorrectorSentenceComponent implements OnInit {
  @Input() sentence: CorrectorSentence;
  @Input() isAutoApproval: boolean;
  @Input() isDebug: boolean;

  @Output() approved = new EventEmitter<CorrectorSentence>();
  @Output() updated = new EventEmitter<CorrectorSentence>();
  @Output() focussed = new EventEmitter<CorrectorSentence>();
  @Output() unfocussed = new EventEmitter<CorrectorSentence>();
  @Output() insertedAfter = new EventEmitter<CorrectorSentence>();
  @Output() removed = new EventEmitter<CorrectorSentence>();
  @Output() split = new EventEmitter<CorrectorSentenceSplitParams>();

  @ViewChild('text', { static: false }) text: ElementRef;
  @ViewChild('tooltip', { static: false }) tooltip: ElementRef;
  @ViewChild('container', { static: false }) container: ElementRef;

  isActive: boolean;
  caretPosition = 0;

  constructor() {}

  ngOnInit() {}

  approveClicked(): void {
    console.log(this.sentence.text);
    this.approved.emit(this.sentence);
  }

  updateClicked(): void {
    this.updated.emit(this.sentence);
  }

  onFocussed(): void {
    this.isActive = true;
    this.updateTooltip();
    this.focussed.emit(this.sentence);
  }

  onUnfocussed(): void {
    this.isActive = false;
    this.updateTooltip();
    this.unfocussed.emit(this.sentence);
  }

  insertAfterClicked(): void {
    this.insertedAfter.emit(this.sentence);
  }

  removeClicked(): void {
    this.removed.emit(this.sentence);
  }

  onSplitClicked(): void {
    this.splitAtCurrentPosition();
    console.log('split');
  }

  focus(): void {
    this.text.nativeElement.focus();
  }

  splitAtCurrentPosition(): void {
    this.split.emit({ sentence: this.sentence, index: this.caretPosition });
  }

  onTextChanged(): void {
    this.caretPosition = this.getCaretIndex();
    this.updateTooltip();

    this.sentence.text = this.text.nativeElement.innerText;
  }

  private updateTooltip(): void {
    // We need to wait a short time, otherwise the tooltip will be removed, before its clicked
    // event can fire.
    const pos = this.getCaretIndex();
    if (this.isActive && pos !== 0 && pos !== this.sentence.text.length) {
      // Show tooltip at caret position
      setTimeout(() => {
        const { x, y } = this.getCaretCoordinates();
        this.tooltip.nativeElement.setAttribute('aria-hidden', 'false');
        this.tooltip.nativeElement.setAttribute(
          'style',
          `display: inline-block; left: ${x - 32}px; top: ${y - 36}px`
        );
      }, 100);
    } else {
      // Hide tooltip
      setTimeout(() => {
        this.tooltip.nativeElement.setAttribute('aria-hidden', 'true');
        this.tooltip.nativeElement.setAttribute('style', 'display: none;');
      }, 100);
    }
  }

  private getCaretIndex(): number {
    let position = 0;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
      const selection = window.getSelection();
      // Check if there is a selection (i.e. cursor in place)
      if (selection.rangeCount !== 0) {
        // Store the original range
        const range = window.getSelection().getRangeAt(0);
        // Clone the range
        const preCaretRange = range.cloneRange();
        // Select all textual contents from the contenteditable element
        preCaretRange.selectNodeContents(this.text.nativeElement);
        // And set the range end to the original clicked position
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        // Return the text length from contenteditable start to the range end
        position = preCaretRange.toString().length;
      }
    }

    return position;
  }

  private getCaretCoordinates() {
    let x = 0,
      y = 0;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
      const selection = window.getSelection();
      // Check if there is a selection (i.e. cursor in place)
      if (selection.rangeCount !== 0) {
        // Clone the range
        const range = selection.getRangeAt(0).cloneRange();
        // Collapse the range to the start, so there are not multiple chars selected
        range.collapse(true);

        // getClientRects returns all the positioning information we need
        const parent = this.container.nativeElement.getClientRects()[0];
        const rect = range.getClientRects()[0];
        if (rect) {
          x = rect.left - parent.left; // since the caret is only 1px wide, left == right
          y = rect.top - parent.top; // top edge of the caret
        }
      }
    }
    return { x, y };
  }
}
