import { Settings } from './settings';
import { Beta } from './beta';
import { LoggingLevel } from '../services/logger.service';

export class User {
  id: string;
  userId: string;
  email: string;
  username: string;
  pictureUrl: string;
  bookedMinutes: number;
  usedSeconds: number;
  settings: Settings;
  loggingLevel: LoggingLevel;
  beta: Beta;
  roles: string[];
  isAdmin: boolean;
  usagePercent: number;
}
