import IAuthProvider from './IAuthProvider';
import TokenResult from './tokenResult';
import { app, authentication } from '@microsoft/teams-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

export class MicrosoftTeamsProvider implements IAuthProvider {
  constructor(private httpClient: HttpClient) {}

  login(): void {
    throw new Error('Method not implemented.');
  }

  logout(): void {
    throw new Error('Method not implemented.');
  }

  async renewTokensAsync(): Promise<TokenResult> {
    await app.initialize();
    const tempToken = await authentication.getAuthToken({});
    return this.exchangeTokenAsync(tempToken);
  }

  resetPasswordAsync(email: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  handleAuthenticationResult(callback: (result: TokenResult, error: any) => void): void {
    app.initialize().then(() => {
      authentication.getAuthToken({}).then(async token => {
        const accessToken = await this.exchangeTokenAsync(token);
        callback(accessToken, null);
      });
    });
  }

  private async exchangeTokenAsync(token: string): Promise<TokenResult> {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + token);
    const response = await this.httpClient
      .post<AadToken>(
        environment.apiServiceUrl + '/api/token/exchange',
        {},
        {
          headers: headers
        }
      )
      .toPromise();

    return {
      accessToken: response.access_token,
      idToken: response.id_token,
      expiresIn: response.expires_in,
      scope: response.scope,
      tokenType: response.token_type
    };
  }
}

class AadToken {
  access_token: string;
  id_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}
