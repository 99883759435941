import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Beta } from '../models/beta';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  beta: Beta = new Beta();

  constructor() {}

  getIsLoading() {
    return this.isLoading;
  }

  setIsLoading(value: boolean) {
    this.isLoading.next(value);
  }

  async sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
}
