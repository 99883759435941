import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({ name: 'highlightHtml' })
export class HighlightHtmlPipe implements PipeTransform {
  transform(text: string, search): string {
    if (!search || search === undefined) {
      return text;
    } else {
      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern
        .split(' ')
        .filter(t => {
          return t.length > 0;
        })
        .join('|');
      pattern = '(' + pattern + ')' + '(?![^<]*>)';
      const regex = new RegExp(pattern, 'gi');

      return search
        ? text.replace(regex, match => `<span class="highlight">${match}</span>`)
        : text;
    }
  }
}
