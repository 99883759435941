import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Language } from '../models/language';
import { Event } from '../models/event';
import { TranscriptDownloadRequest, TranscriptFormat } from '../models/transcriptDownloadRequest';

@Component({
  selector: 'app-transcript-download-dialog',
  templateUrl: 'transcript-download.dialog.component.html'
})
export class TranscriptDownloadDialogComponent {
  hasTranslation = false;
  languages: Language[] = [];
  formats: TranscriptFormat[] = [
    TranscriptFormat.TXT,
    TranscriptFormat.PDF,
    TranscriptFormat.SRT,
    TranscriptFormat.VTT
  ];

  selectedTranslationLanguageCode: string;
  selectedFormat: TranscriptFormat;

  constructor(
    public dialogRef: MatDialogRef<TranscriptDownloadDialogComponent, TranscriptDownloadRequest>,
    @Inject(MAT_DIALOG_DATA) public data: TranscriptDownloadDialogData
  ) {
    this.hasTranslation = data.event.hasTranslation;
    this.languages = [];
    this.languages.push(Language.getOriginal());
    data.event.translationLanguages.forEach(element => {
      this.languages.push(data.languages.find(x => x.code === element));
    });

    this.selectedTranslationLanguageCode = this.languages[0].translationLanguageCode;
    this.selectedFormat = this.formats[0];
  }

  submit(): void {
    const result = new TranscriptDownloadRequest();
    result.format = this.selectedFormat;
    result.translationLanguageCode = this.selectedTranslationLanguageCode;
    this.dialogRef.close(result);
  }
}

export class TranscriptDownloadDialogData {
  event: Event;
  languages: Language[];
}
