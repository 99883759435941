import { app } from '@microsoft/teams-js';

class LaunchContext {
  public readonly appMode: string;
  public readonly teamsFeature: string | null;
  private microsoftTeamsDetectionSucceeded: boolean;

  public constructor() {
    const urlParams = new URLSearchParams(window.location.search);

    this.appMode = urlParams.get('app-mode') || 'default';
    this.teamsFeature = urlParams.get('teamsFeature');
    this.microsoftTeamsDetectionSucceeded = false;

    try {
      app
        .initialize()
        .then((): void => {
          this.microsoftTeamsDetectionSucceeded = true;
          console.log('Microsoft Teams detection succeeded.');
        })
        .catch((): void => {
          console.log('Microsoft Teams detection failed.');
        });
    } catch {
      // nothing to do, was just a try
    }
  }

  public get isTeamsMode(): boolean {
    return this.microsoftTeamsDetectionSucceeded || this.appMode === 'microsoft-teams';
  }

  public get isTeamsTabContext(): boolean {
    return this.teamsFeature === 'channelTab';
  }
}

export default new LaunchContext();
