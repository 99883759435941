import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AdminService } from 'src/app/services/api/admin.service';
import { User } from 'src/app/models/user';
import { MessageService } from 'src/app/services/message.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { Event } from 'src/app/models/event';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { Backup } from 'src/app/models/backup';
import { VocabularyBook } from 'src/app/models/vocabularyBook';
import { SpeechModel } from 'src/app/models/speechModel';
import { BookingLog } from 'src/app/models/bookingLog';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  users: User[];
  events: Event[];
  vocabularyBooks: VocabularyBook[];
  speechModels: SpeechModel[];
  backups: Backup[];
  bookingLogs: BookingLog[];
  isLoading: boolean;
  displayedUserColumns: string[];
  displayedEventColumns: string[];
  displayedVocabularyBookColumns: string[];
  displayedSpeechModelColumns: string[];
  displayedBookingLogColumns: string[];
  usersDataSource: MatTableDataSource<User>;
  eventsDataSource: MatTableDataSource<Event>;
  vocabularyBooksDataSource: MatTableDataSource<VocabularyBook>;
  speechModelsDataSource: MatTableDataSource<SpeechModel>;
  bookingLogsDataSource: MatTableDataSource<BookingLog>;

  @ViewChild('sortUsers', { static: false }) sortUsers: MatSort;
  @ViewChild('sortEvents', { static: false }) sortEvents: MatSort;
  @ViewChild('sortVocabularyBooks', { static: false }) sortVocabularyBooks: MatSort;
  @ViewChild('sortSpeechModels', { static: false }) sortSpeechModels: MatSort;
  @ViewChild('sortBookingLogs', { static: false }) sortBookingLogs: MatSort;

  private subscriptionManager = new SubscriptionManager();

  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private globals: GlobalsService,
    private router: Router
  ) {
    this.users = [];
    this.events = [];
    this.vocabularyBooks = [];
    this.speechModels = [];
    this.backups = [];
    this.bookingLogs = [];

    this.displayedUserColumns = [
      'userId',
      'username',
      'email',
      'bookedMinutes',
      'usedSeconds',
      'usagePercent',
      'action'
    ];
    this.displayedEventColumns = [
      'id',
      'name',
      'userId',
      'url',
      'language',
      'hasCorrector',
      'isDeleted',
      'duration',
      'action'
    ];
    this.displayedVocabularyBookColumns = ['id', 'name', 'userId', 'language', 'lastUsed', 'state', 'action'];
    this.displayedSpeechModelColumns = ['name', 'description', 'status'];
    this.displayedBookingLogColumns = ['timestamp', 'executor', 'userId', 'delta'];
    this.usersDataSource = new MatTableDataSource(this.users);
    this.eventsDataSource = new MatTableDataSource(this.events);
    this.vocabularyBooksDataSource = new MatTableDataSource(this.vocabularyBooks);
    this.speechModelsDataSource = new MatTableDataSource(this.speechModels);
    this.bookingLogsDataSource = new MatTableDataSource(this.bookingLogs);
  }

  async ngOnInit() {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));
    await this.getAllUsersAsync();
  }

  async onTabChanged($event) {
    switch ($event.index) {
      case 0: // Users
        await this.getAllUsersAsync();
        break;
      case 1: // Events
        await this.getAllEventsAsync();
        break;
      case 2: // Vocabulary books
        await this.getAllVocabularyBooksAsync();
        break;
      case 3: // Speech models
        await this.getAllSpeechModelsAsync();
        break;
      case 4: // Booking logs
        await this.getAllBookingLogsAsync();
        break;
    }
  }

  applyFilter(filterValue: string) {
    this.usersDataSource.filter = filterValue.trim().toLowerCase();
    this.eventsDataSource.filter = filterValue.trim().toLowerCase();
    this.vocabularyBooksDataSource.filter = filterValue.trim().toLowerCase();
    this.speechModelsDataSource.filter = filterValue.trim().toLowerCase();
    this.bookingLogsDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }

  async retrainVocabularyBookAsync(id: string): Promise<void> {
    // Show warning
    if (
      !(await this.messageService.showActionDialogAsync(
        'Restart training?',
        'Do you really want to restart the training for this vocabulary book? Triggering retraining ' +
          'as an administrator, will not cause any costs to the customer.',
        'Restart training',
        'Cancel'
      ))
    ) {
      return;
    }

    try {
      this.globals.setIsLoading(true);
      await this.adminService.retrainVocabularyBookAsync(id);
      this.vocabularyBooks = await this.adminService.getAllVocabularyBooksAsync();
      this.vocabularyBooksDataSource = new MatTableDataSource(this.vocabularyBooks);
      this.vocabularyBooksDataSource.sort = this.sortVocabularyBooks;
    } catch (error) {
      console.error(error);
      this.messageService.showToast('An error occurred while retraining vocabulary book.');
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private async getAllUsersAsync(): Promise<void> {
    try {
      this.globals.setIsLoading(true);
      this.users = await this.adminService.getAllUsersAsync();
      this.usersDataSource = new MatTableDataSource(this.users);
      this.usersDataSource.sort = this.sortUsers;
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private async getAllEventsAsync(): Promise<void> {
    try {
      this.globals.setIsLoading(true);
      this.events = await this.adminService.getAllEventsAsync();
      this.eventsDataSource = new MatTableDataSource(this.events);
      this.eventsDataSource.sort = this.sortEvents;
    } catch (error) {
      this.handleErrorResponse(error);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private async getAllVocabularyBooksAsync(): Promise<void> {
    try {
      this.globals.setIsLoading(true);
      this.vocabularyBooks = await this.adminService.getAllVocabularyBooksAsync();
      this.vocabularyBooksDataSource = new MatTableDataSource(this.vocabularyBooks);
      this.vocabularyBooksDataSource.sort = this.sortVocabularyBooks;
    } catch (error) {
      this.handleErrorResponse(error);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private async getAllSpeechModelsAsync(): Promise<void> {
    try {
      this.globals.setIsLoading(true);
      this.speechModels = await this.adminService.getAllSpeechModelsAsync();
      this.speechModelsDataSource = new MatTableDataSource(this.speechModels);
      this.speechModelsDataSource.sort = this.sortSpeechModels;
    } catch (error) {
      this.handleErrorResponse(error);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private async getAllBookingLogsAsync(): Promise<void> {
    try {
      this.globals.setIsLoading(true);
      this.bookingLogs = await this.adminService.getAllBookingLogsAsync();
      this.bookingLogsDataSource = new MatTableDataSource(this.bookingLogs);
      this.bookingLogsDataSource.sort = this.sortBookingLogs;
    } catch (error) {
      this.handleErrorResponse(error);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  private handleErrorResponse(error: any): void {
    if (error.status === 403) {
      // User is not allowed to access. Navigate back to home
      this.messageService.showToast('No access');
      this.router.navigate(['/']);
    } else {
      console.error(error);
      this.messageService.showToast('An error occurred.');
    }
  }
}
