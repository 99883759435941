import { Component, OnInit } from '@angular/core';
import ElectronIpc from 'src/app/helpers/electronIpc';
import { ActivatedRoute } from '@angular/router';
import { detect } from 'detect-browser';
import { MessageService } from 'src/app/services/message.service';
import { app, pages } from '@microsoft/teams-js';
import launchContext from 'src/app/models/launchContext';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {
  private readonly electronIpc: ElectronIpc;

  isDesktop = false;
  isMac = false;
  isWindows = false;
  isUrlValid = false;
  isConnectButtonVisible = true;
  url = '';

  constructor(private route: ActivatedRoute, private messageService: MessageService) {
    this.electronIpc = new ElectronIpc();
    this.isDesktop = this.electronIpc.enabled;

    const browser = detect();
    this.isWindows = browser.os.indexOf('Windows') >= 0;
    this.isMac = browser.os === 'Mac OS';
    this.isConnectButtonVisible = !launchContext.isTeamsMode;
  }

  async ngOnInit() {
    if (launchContext.isTeamsMode) {
      await app.initialize();
      pages.config.registerOnSaveHandler(saveEvent => {
        const teamsUrl = new URL(this.url);
        teamsUrl.searchParams.set('app-mode', 'microsoft-teams');
        console.log('url', teamsUrl.toString());

        pages.config.setConfig({
          suggestedDisplayName: 'Transcription',
          contentUrl: teamsUrl.toString(),
          websiteUrl: this.url,
          entityId: this.url
        });
        saveEvent.notifySuccess();
      });
    }

    const eventUrl = this.route.snapshot.queryParams['url'];
    if (eventUrl) {
      this.url = eventUrl;
    }
  }

  onUrlChanged(event: any): void {
    this.isUrlValid = this.url.length > 0 && this.isValidUrl(this.url);

    // Enable "Save Button" in Microsoft Teams
    if (launchContext.isTeamsMode) {
      pages.config.setValidityState(this.isUrlValid);
    }
  }

  async connectAsync(): Promise<void> {
    // Check URL
    if (!this.isValidUrl(this.url) || this.url.indexOf('/listener/') < 0) {
      await this.messageService.showDialogAsync(
        'Invalid Listener Link',
        'Please enter the Listener URL for the event you want to connect to.\n' +
          'It ususally starts with: https://app.starteve.ai/listener/...',
        'OK'
      );
      return;
    }

    if (this.electronIpc.enabled) {
      this.electronIpc.invokeUrlChanged(this.url);
    } else {
      window.location.replace(this.url);
    }
  }

  private isValidUrl(url: string): boolean {
    try {
      const test = new URL(url);
      return true;
    } catch {
      return false;
    }
  }
}
