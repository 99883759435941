import IAuthProvider from './IAuthProvider';
import * as auth0 from 'auth0-js';
import Auth0Config from './auth0config';
import TokenResult from './tokenResult';

export class Auth0Provider implements IAuthProvider {
  private clientId: string;
  private logoutRedirectUrl: string;
  private webAuth: auth0.WebAuth;

  constructor(authConfig: Auth0Config) {
    this.clientId = authConfig.clientId;
    this.logoutRedirectUrl = authConfig.logoutRedirectUrl;
    this.webAuth = new auth0.WebAuth({
      clientID: authConfig.clientId,
      domain: authConfig.domain,
      redirectUri: authConfig.loginRedirectUri,
      responseType: authConfig.responseType,
      audience: authConfig.audience,
      scope: authConfig.scope
    });
  }

  login(): void {
    this.webAuth.authorize();
  }

  logout(): void {
    this.webAuth.logout({
      returnTo: this.logoutRedirectUrl,
      clientID: this.clientId
    });
  }

  renewTokensAsync(): Promise<TokenResult> {
    console.log('Renewing tokens in the background...');
    return new Promise((resolve, reject) => {
      // Renew access token
      this.webAuth.checkSession({}, (error, result) => {
        if (result && result.accessToken && result.idToken) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  resetPasswordAsync(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          email: email,
          connection: 'Username-Password-Authentication'
        },
        err => {
          if (err) {
            reject();
            console.error(err);
            throw new Error('An error occurred while trying to reset the password.');
          }
        }
      );
      resolve();
    });
  }

  handleAuthenticationResult(callback: (result: TokenResult, error: any) => void): void {
    this.webAuth.parseHash((err, result) => {
      if (result && result.accessToken && result.idToken) {
        callback(result, err);
      } else {
        callback(null, err);
      }
    });
  }
}
