import Plugins from './plugins';

export class Event {
  id: string;
  name = '';
  url = '';
  message: string;
  logoUrl: string;
  language: string;
  userId: string;
  hasCorrector: boolean;
  isCorrectorLanguageChangeAllowed: boolean;
  isCorrectorClearSentencesAllowed: boolean;
  hasSwearwordFilter: boolean;
  hasTranslation: boolean;
  translationLanguages: string[];
  isDeleted: boolean;
  duration: number;
  startDate: Date;
  hasPassword: boolean;
  password: string;
  hasVocabularyBooks: boolean;
  vocabularyBooks: { [key: string]: string };
  blockedWords: string[];
  speechConfigId: string;
  plugins: Plugins;
  createdAt: Date;
  readonly hasBlockedWords: boolean;
  readonly supportsSrtExport: boolean;

  // Used locally for file upload only
  logoData: File;

  constructor() {
    this.hasCorrector = false;
    this.hasSwearwordFilter = false;
    this.hasTranslation = false;
    this.hasPassword = false;
    this.blockedWords = [];
    this.translationLanguages = [];
    this.duration = 0;
    this.plugins = new Plugins();
  }
}
