import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/api/user.service';
import * as copy from 'copy-to-clipboard';
import { MessageService } from 'src/app/services/message.service';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectionService } from 'src/app/services/device-detection.service';
import { Sentence } from 'src/app/models/sentence';
import { CorrectorSentence } from 'src/app/models/correctorSentence';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  version: string;
  build: string;
  os: string;
  browser: string;
  browserVersion: string;
  isMobile: boolean;
  userId: string;
  beta: string;
  isApiConnected: boolean;

  sentences: CorrectorSentence[] = [];

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private deviceDetectionService: DeviceDetectionService,
    private httpClient: HttpClient
  ) {}

  async ngOnInit() {
    // General
    this.version = environment.version;
    this.build = environment.build;
    this.os = this.deviceDetectionService.getOS();
    this.browser = this.deviceDetectionService.getBrowserName();
    this.browserVersion = this.deviceDetectionService.getBrowserVersion();
    this.isMobile = this.deviceDetectionService.getIsMobile();

    // User
    const currentUser = await this.userService.getCurrentUserAsync();
    this.userId = currentUser.userId;

    // Beta
    this.beta = JSON.stringify(currentUser.beta, null, 2);

    // Connection
    await this.checkConnectionAsync();
  }

  copy(): void {
    const details =
      `version: "${this.version} (Build: ${this.build})",\n` +
      `os: "${this.os},"\n` +
      `browser: "${this.browser}",\n` +
      `browserVersion: "${this.browserVersion}",\n` +
      `userId: "${this.userId}",\n` +
      `beta: ${this.beta}\n` +
      `isApiConnected: "${this.isApiConnected}",\n`;

    if (copy(details, { format: 'text/plain' })) {
      this.messageService.showToast('Copied to clipboard');
    }
  }

  private async checkConnectionAsync(): Promise<void> {
    try {
      await this.httpClient.get(`${environment.apiServiceUrl}/index.html`).toPromise();
      this.isApiConnected = true;
    } catch {
      this.isApiConnected = false;
    }
  }
}
