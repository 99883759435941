// From: https://netbasal.com/disabling-form-controls-when-working-with-reactive-forms-in-angular-549dd7b42110

import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[controlDisabled]'
})
export class ControlDisabledDirective {
  @Input() set controlDisabled(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {}
}
