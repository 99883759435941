import { Injectable } from '@angular/core';
import { BotInfo, BrowserInfo, detect, NodeInfo } from 'detect-browser';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  private browser: BrowserInfo | BotInfo | NodeInfo;

  constructor() {
    this.browser = detect();
  }

  getOS(): string {
    return this.browser.os;
  }

  getBrowserName(): string {
    return this.browser.name;
  }

  getBrowserVersion(): string {
    return this.browser.version;
  }

  getIsMobile(): boolean {
    const os = this.browser.os;
    return (
      os === 'Android OS' ||
      os === 'android' ||
      os === 'iOS' ||
      os === 'Windows Mobile' ||
      os === 'BlackBerry OS'
    );
  }
}
