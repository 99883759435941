import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from 'src/app/services/globals.service';
import * as _ from 'lodash';
import { BookingLog } from 'src/app/models/bookingLog';

@Component({
  selector: 'app-booking-logs',
  templateUrl: './booking-logs.component.html',
  styleUrls: ['./booking-logs.component.scss']
})
export class BookingLogsComponent implements OnChanges {
  @Input() items: BookingLog[];
  @Input() transparent: boolean;
  @ViewChild('sortItems', { static: true }) sortItems: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['timestamp', 'executor', 'userId', 'delta'];
  dataSource: any;

  constructor(public globals: GlobalsService) {
    this.items = [];
    this.transparent = false;
    this.dataSource = new MatTableDataSource(this.items);
  }

  async ngOnChanges() {
    this.items = _.sortBy(this.items, x => x.timestamp).reverse();
    this.dataSource = new MatTableDataSource(this.items);
    this.dataSource.sort = this.sortItems;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
