import { Injectable } from '@angular/core';
import { Billing, ReferenceType } from '../../models/billing';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { BaseApiService } from './base-api.service';
import { environment } from 'src/environments/environment';
import { VocabularyBook } from '../../models/vocabularyBook';
import { Event } from '../../models/event';
import { Dictionary } from '../../interfaces/dictionary';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseApiService {
  private readonly SERVICE_API_URL = '/api/billing';

  private billings: Billing[];
  private billingsNameCache: Dictionary<string>;

  constructor(private httpClient: HttpClient, authService: AuthService) {
    super(authService);
    this.billings = [];
    this.billingsNameCache = {};
  }

  async getBillingsAsync(): Promise<Billing[]> {
    // Try to return from local cache
    if (this.billings.length > 0) {
      return this.billings;
    }

    try {
      const response = await this.httpClient
        .get<Billing[]>(environment.apiServiceUrl + this.SERVICE_API_URL, {
          headers: await this.getHeadersAsync(),
        })
        .toPromise();

      this.billings = response;
      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to load the list of billings.');
    }
  }

  updateBillingReference(billing: Billing, events: Event[], books: VocabularyBook[]) {
    // Return from local cache is possible
    if (this.billingsNameCache[billing.id]) {
      billing.referenceName = this.billingsNameCache[billing.id];
      billing.referenceFound = true;
      return;
    }

    switch (billing.referenceType) {
      case ReferenceType.Event:
        const event = events.find((x) => x.id === billing.referenceId);
        if (event) {
          billing.referenceName = event.name;
          billing.referenceFound = true;
          this.addToCache(billing.id, event.name);
        } else {
          billing.referenceName = 'Deleted';
          billing.referenceFound = false;
        }
        break;

      case ReferenceType.VocabularyBook:
        const book = books.find((x) => x.id === billing.referenceId);
        if (book) {
          billing.referenceName = book.name;
          billing.referenceFound = true;
          this.addToCache(billing.id, book.name);
        } else {
          billing.referenceName = 'Deleted';
          billing.referenceFound = false;
        }
        break;

      default:
        billing.referenceName = 'Unknown';
        billing.referenceFound = false;
        break;
    }
  }

  addToCache(billingId: string, referenceTypeName: string): string {
    this.billingsNameCache[billingId] = referenceTypeName;
    return referenceTypeName;
  }
}
