import { IRetryPolicy, RetryContext } from '@microsoft/signalr';

export class SignalRRetryPolicy implements IRetryPolicy {
  // Retry every 3s 10 times (= 30 seconds)
  nextRetryDelayInMilliseconds(retryContext: RetryContext): number {
    if (retryContext.previousRetryCount < 10) {
      console.log(`${retryContext.previousRetryCount + 1}. SignalR reconnect attempt...`);
      return 3000;
    } else {
      console.log(
        `Stopped SignalR reconnect attempts after ${retryContext.elapsedMilliseconds / 1000}s.`
      );
      return null;
    }
  }
}
