import { HubConnectionState } from '@microsoft/signalr';

export enum ConnectionState {
  Disconnected = 'Disconnected',
  Connected = 'Connected',
  Connecting = 'Connecting',
  Reconnecting = 'Reconnecting',
  Failed = 'Failed'
}

export class ConnectionStateUtils {
  static fromHubConnectionState(state: HubConnectionState): ConnectionState {
    switch (state) {
      default:
      case HubConnectionState.Disconnected:
        return ConnectionState.Disconnected;
      case HubConnectionState.Connected:
        return ConnectionState.Connected;
      case HubConnectionState.Connecting:
        return ConnectionState.Connecting;
      case HubConnectionState.Disconnecting:
        return ConnectionState.Disconnected;
      case HubConnectionState.Reconnecting:
        return ConnectionState.Reconnecting;
    }
  }
}
