import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationFailedError } from '../errors/authentication-failed-error';
import Auth0Config from '../auth/auth0config';
import IAuthProvider from '../auth/IAuthProvider';
import { Auth0Provider } from '../auth/auth0provider';
import TokenResult from '../auth/tokenResult';
import launchContext from '../models/launchContext';
import { MicrosoftTeamsProvider } from '../auth/microsoftTeamsProvider';
import { HttpClient } from '@angular/common/http';
import { AuthProviderType } from '../enums/authProviderType';
import { AzureActiveDirectoryProvider } from '../auth/azureActiveDirectoryProvider';
import AzureActiveDirectoryConfig from '../auth/azureActiveDirectoryConfig';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authProviders: { [key in AuthProviderType]?: IAuthProvider } = {};
  private currentAuthProvider: IAuthProvider;
  private accessTokenExpiresAt: number;
  private renewalTimer: any;
  private accessToken: string;

  isLoggedIn: BehaviorSubject<boolean>;

  constructor(
    public router: Router,
    httpClient: HttpClient,
    @Inject('auth0Config') auth0Config: Auth0Config,
    @Inject('aadConfig') aadConfig: AzureActiveDirectoryConfig
  ) {
    // Initialize auth provider
    if (launchContext.isTeamsMode) {
      this.authProviders[AuthProviderType.Teams] = new MicrosoftTeamsProvider(httpClient);

      // Set current auth provider to teams, because we are in teams mode
      this.setCurrentAuthProvider(AuthProviderType.Teams);
    } else {
      if (environment.auth0Enabled) {
        this.authProviders[AuthProviderType.Auth0] = new Auth0Provider(auth0Config);
      }

      if (environment.aadEnabled) {
        this.authProviders[AuthProviderType.AzureActiveDirectory] = new AzureActiveDirectoryProvider(aadConfig);
      }

      // Throw error, if no auth provider has been registered
      if (Object.keys(this.authProviders).length === 0) {
        throw new Error('No auth provider has been registered. Please register at least one auth provider.');
      }

      this.restoreCurrentAuthProvider();
    }

    // Load login information from local storage
    this.accessToken = localStorage.getItem('access_token');
    this.accessTokenExpiresAt = JSON.parse(localStorage.getItem('access_token_expires_at'));

    // Set isLoggedIn based on token availability
    // It does not play a role if the token is expired, because we will try to refresh it anyways.
    // If that refreshment fails, the user will get logged out.
    const initialIsLoggedInState = this.accessToken != null;
    this.isLoggedIn = new BehaviorSubject<boolean>(initialIsLoggedInState);
    console.log('AuthService isLoggedIn initialized with: ' + initialIsLoggedInState);
  }

  login(provider: AuthProviderType) {
    this.setCurrentAuthProvider(provider);
    this.currentAuthProvider.login();
  }

  async getAccessTokenAsync(): Promise<string> {
    // Check if access token is available and does not expire within the next 60s
    const timeBeforeWarning = 60 * 1000;
    if (this.accessToken && this.accessTokenExpiresAt > Date.now() - timeBeforeWarning) {
      // Check, if renewal timer is running
      if (!this.renewalTimer) {
        this.scheduleTokenRenewal();
      }

      return this.accessToken;
    }

    // Try to renew tokens otherwise
    const renewalSuccess = await this.renewTokensAsync();
    if (renewalSuccess) {
      return this.accessToken;
    } else {
      console.warn('Could not renew access token.');
      this.isLoggedIn.next(false);
      throw new AuthenticationFailedError('Could not renew access token.');
    }
  }

  scheduleTokenRenewal() {
    // Unschedule old renewal
    this.unscheduleTokenRenewal();

    // Start new automatic token renewal timer
    const renewIn = Math.max(1, this.accessTokenExpiresAt - Date.now());
    this.renewalTimer = setTimeout(async () => {
      await this.renewTokensAsync();
    }, renewIn);

    console.log('New Token renewal scheduled.');
  }

  private unscheduleTokenRenewal() {
    if (this.renewalTimer) {
      clearTimeout(this.renewalTimer);
      console.log('Token renewal unscheduled.');
    }
  }

  private async renewTokensAsync(): Promise<TokenResult> {
    const result = await this.currentAuthProvider.renewTokensAsync();
    if (result) {
      console.log('Access token renewed successfully.');
      this.saveTokensLocally(result);
      this.scheduleTokenRenewal();
    } else {
      console.warn('Could not get a new access token. Deleting the existing one...');
      this.deleteLocalTokens();
      this.unscheduleTokenRenewal();
      this.isLoggedIn.next(false);
    }

    return result;
  }

  logout() {
    // Remove tokens and expiry time
    this.deleteLocalTokens();

    // Cancel scheduled token renewal
    this.unscheduleTokenRenewal();

    this.isLoggedIn.next(false);

    this.currentAuthProvider.logout();
  }

  private saveTokensLocally(authResult: TokenResult) {
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

    this.accessToken = authResult.accessToken;
    this.accessTokenExpiresAt = expiresAt;
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('access_token_expires_at', JSON.stringify(expiresAt));
  }

  private deleteLocalTokens() {
    this.accessToken = '';
    this.accessTokenExpiresAt = 0;
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
  }

  async resetPasswordAsync(email: string): Promise<void> {
    await this.currentAuthProvider.resetPasswordAsync(email);
  }

  private setCurrentAuthProvider(provider: AuthProviderType): void {
    this.currentAuthProvider = this.authProviders[provider];
    localStorage.setItem('auth_provider', provider);
  }

  private restoreCurrentAuthProvider() {
    const authProviderType = localStorage.getItem('auth_provider');
    if (!authProviderType || authProviderType === AuthProviderType.Unknown) {
      // Default if no value is set
      // If Auth0 is enabled, use Auth0 as default
      if (environment.auth0Enabled) {
        this.currentAuthProvider = this.authProviders[AuthProviderType.Auth0];
        console.log('No current auth provider found. Defaulted to Auth0');
        // Otherwise, if AAD is enabled, use AAD as default
      } else if (environment.aadEnabled) {
        this.currentAuthProvider = this.authProviders[AuthProviderType.AzureActiveDirectory];
        console.log('No current auth provider found. Defaulted to Active Directory');
      } else {
        console.error('No current auth provider found. Please enable at least one auth provider.');
      }
    } else {
      this.currentAuthProvider = this.authProviders[authProviderType];
      console.log('Restored current auth provider: ' + authProviderType);
    }
  }

  /**
   * Handles authentication when being redirected from Auth0.
   * The route contains an #access_token then, which can get parsed and
   * processed by the Auth0 library automatically. This method needs to
   * be called, when such an #access_token is available in the URL.
   */
  handleAuthentication() {
    this.currentAuthProvider.handleAuthenticationResult((result, error) => {
      if (result) {
        this.saveTokensLocally(result);
        this.isLoggedIn.next(true);

        if (window.location.pathname.indexOf('callback') > 0 || window.location.pathname.indexOf('login') > 0) {
          this.router.navigate(['/']);
        }
      }

      if (error) {
        this.isLoggedIn.next(false);
        console.log('Authentication failed.', error);
      }
    });
  }
}
