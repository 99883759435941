import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { Language } from '../../models/language';
import { environment } from 'src/environments/environment';
import { LocalCache } from '../../helpers/localCache';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseApiService {
  private cachedLanguages: LocalCache<Language>;

  constructor(private httpClient: HttpClient, authService: AuthService) {
    super(authService);
    this.cachedLanguages = new LocalCache<Language>();
  }

  async getAllAsync(): Promise<Language[]> {
    // Try to return from local cache
    if (this.cachedLanguages.hasItems()) {
      return this.cachedLanguages.getItems();
    }

    try {
      const response = await this.httpClient
        .get<Language[]>(environment.apiServiceUrl + '/api/language', {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Update cache
      this.cachedLanguages.replace(response);

      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to get all languages.');
    }
  }

  async getByCodeAsync(code: string): Promise<Language> {
    // Try to return from local cache
    const cachedLanguage = this.cachedLanguages.getItem(x => x.code === code);
    if (cachedLanguage) {
      return cachedLanguage;
    }

    try {
      const response = await this.httpClient
        .get<Language>(environment.apiServiceUrl + '/api/language/' + code, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to get language with code ' + code + '.');
    }
  }

  async getRecordingLanguagesAsync(): Promise<Language[]> {
    const languages = await this.getAllAsync();
    return languages.filter(x => x.isAvailableForRecording);
  }

  async getTranslationLanguagesAsync(): Promise<Language[]> {
    const languages = await this.getAllAsync();
    return languages.filter(x => x.isAvailableForTranslation);
  }

  async getVocabularyBookLanguagesAsync(): Promise<Language[]> {
    const languages = await this.getAllAsync();
    return languages.filter(x => x.isAvailableForVocabularyBook);
  }

  /**
   * Returns a list of full languages based on given language codes.
   * @param languages Array of language codes
   */
  async getTranslationLanguagesAsyncFor(languages: string[]): Promise<Language[]> {
    const allLanguages = await this.getAllAsync();
    return allLanguages.filter(x => languages.indexOf(x.code) > -1);
  }
}
