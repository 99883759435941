import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/services/message.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { Subscription } from 'rxjs';
import { ListenerService } from 'src/app/services/listener.service';
import { SubscriptionManager } from '../helpers/subscriptionManager';

@Component({
  selector: 'app-password-dialog',
  templateUrl: 'password.dialog.component.html'
})
export class PasswordDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  subscription: Subscription;
  password: string;

  private subscriptionManager = new SubscriptionManager();

  constructor(
    public globals: GlobalsService,
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    private listenerService: ListenerService,
    private messageService: MessageService
  ) {
    // Keep dialog open until a password is confirmed
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.subscriptionManager.add(
      this.globals.getIsLoading().subscribe(value => (this.isLoading = value)),
      this.listenerService.passwordConfirmed$.subscribe(message => {
        this.globals.setIsLoading(false);
        this.dialogRef.close();
        this.messageService.showToast(message);
      }),
      this.listenerService.passwordErrored$.subscribe(message => {
        this.globals.setIsLoading(false);
        console.log(`Error! ${message}`);
        this.messageService.showToast(message);
      })
    );
  }

  async submit() {
    this.globals.setIsLoading(true);
    await this.listenerService.validateEventPassword(this.password);
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }
}
