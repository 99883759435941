export class LocalCache<T> {
  private cache: T[];

  constructor() {
    this.cache = [];
  }

  hasItems(): boolean {
    return this.cache.length > 0;
  }

  getItems(): T[] {
    return this.cache;
  }

  getItem(predicate: (value: T, index: number, obj: T[]) => boolean): T {
    return this.cache.find(predicate);
  }

  addItem(item: T): void {
    this.cache.push(item);
  }

  updateItem(predicate: (value: T, index: number, obj: T[]) => boolean, item: T): void {
    const index = this.cache.findIndex(predicate);
    if (index >= 0) {
      this.cache[index] = item;
    }
  }

  deleteItem(predicate: (value: T, index: number, obj: T[]) => boolean): void {
    const index = this.cache.findIndex(predicate);
    if (index > 0) {
      this.cache.splice(index, 1);
    } else {
      console.warn('Can not find item to delete from cache');
    }
  }

  replace(items: T[]) {
    this.cache = items;
  }
}
