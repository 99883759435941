import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stopwatch'
})
export class StopwatchPipe implements PipeTransform {
  transform(sec: number, hideEmptyHours: boolean = false): any {
    let minus = false;
    if (sec < 0) {
      minus = true;
      sec = sec * -1;
    }

    const minutes = sec / 60;
    const hours = minutes / 60;
    const roundedHours = Math.floor(hours);
    const roundedMinutes = Math.floor(minutes - roundedHours * 60);
    const roundedSeconds = Math.round((sec - roundedMinutes * 60 - roundedHours * 60 * 60) * 100) / 100; // * 100 / 100 = round to 2 decimals

    // Add minus sign
    const displaySign = minus ? '-' : '';

    // Add training zeroes
    let displayHours = '';
    if (roundedHours < 10) {
      displayHours += '0';
    }
    displayHours += roundedHours;
    const displayMinutes = ('0' + roundedMinutes).slice(-2);
    const displaySeconds = roundedSeconds < 9 ? '0' + roundedSeconds : roundedSeconds;

    // Hide empty hours section
    if (hideEmptyHours && roundedHours === 0) {
      return `${displaySign}${displayMinutes}:${displaySeconds}`;
    } else {
      return `${displaySign}${displayHours}:${displayMinutes}:${displaySeconds}`;
    }
  }
}
