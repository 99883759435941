import { DefaultDialogButton } from './defaultDialogButton';

export class DefaultDialogData {
  title: string;
  text: string;
  defaultButton: DefaultDialogButton;
  otherButtons: DefaultDialogButton[];

  constructor(
    title: string,
    text: string,
    defaultButton: DefaultDialogButton,
    otherButtons: DefaultDialogButton[]
  ) {
    this.title = title;
    this.text = text;
    this.defaultButton = defaultButton;
    this.otherButtons = otherButtons;
  }
}
