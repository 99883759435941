import { getQueryParameters } from './queryParametersHelpers';
import { Settings } from '../models/settings';
import { Event } from '../models/event';
import { Language } from '../models/language';
import { Subject } from 'rxjs';

// Inter process communication
export default class ElectronIpc {
  private readonly electronModeEnabled: boolean;
  private readonly ipcRenderer: any;

  public get enabled(): boolean {
    return this.electronModeEnabled;
  }

  languageChanged = new Subject<Language>();

  public constructor() {
    // Check, if electron mode is enabled via query parameter
    this.electronModeEnabled = getQueryParameters().has('electronMode');
    if (this.electronModeEnabled) {
      this.ipcRenderer =
        // @ts-ignore
        window.require('electron').ipcRenderer;

      this.ipcRenderer.on('languageChanged', (event, langauge) => {
        this.languageChanged.next(langauge);
      });
    }
  }

  public send(channel: string, ...args: any): void {
    if (!this.electronModeEnabled) {
      return;
    }
    this.ipcRenderer.send(channel, ...args);
  }

  public invoke(channel: string, ...args: any): Promise<any> {
    if (!this.electronModeEnabled) {
      return;
    }
    return this.ipcRenderer.invoke(channel, ...args);
  }

  public async getSettings(): Promise<Settings> {
    const settings = await this.invoke('getSettings');
    // set the prototype to use class functionality like getters
    Object.setPrototypeOf(settings, new Settings());
    return settings;
  }

  public invokeEventChanged(event: Event, languages: Language[]) {
    this.ipcRenderer.send('eventChanged', event, languages);
  }

  public invokeUrlChanged(url: string) {
    this.ipcRenderer.send('urlChanged', url);
  }
}
