import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Settings } from '../../models/settings';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private userService: UserService) {}

  async getSettings(): Promise<Settings> {
    // Try to get current user settings
    try {
      const user = await this.userService.getCurrentUserAsync();
      return new Settings(user.settings);
    } catch {
      // No user was found or no user is logged in.
      // Check if settings can be found in cookies.
      console.log('No user is logged in, so locally stored settings (if any) are used.');
      const localSettingsJson = localStorage.getItem('settings');
      if (localSettingsJson) {
        // Found settings in cookies.
        // Use them.
        const localSettings = JSON.parse(localSettingsJson);
        return new Settings(localSettings);
      } else {
        // No settings in cookies have been found.
        // Return default settings
        return new Settings();
      }
    }
  }

  async saveSettings(settings: Settings): Promise<Settings> {
    // Get current user to save settings to.
    let user: User;

    try {
      user = await this.userService.getCurrentUserAsync();
      user.settings = settings;
    } catch {
      // No current user found.
      // Save settings to local storage.
      console.log('No user is logged in, so settings are saved to local storage.');
      localStorage.setItem('settings', JSON.stringify(settings));
      return settings;
    }

    try {
      const updatedUser = await this.userService.updateUserAsync(user);
      return updatedUser.settings;
    } catch {
      throw new Error('An error occurred while trying to save settings.');
    }
  }
}
