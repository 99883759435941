import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Event } from 'src/app/models/event';
import { MessageService } from 'src/app/services/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/api/event.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { VocabularyBookService } from 'src/app/services/api/vocabulary-book.service';
import { VocabularyBook, VocabularyBookState, VocabularyBookStateUtil } from 'src/app/models/vocabularyBook';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Language } from 'src/app/models/language';
import { LanguageService } from 'src/app/services/api/language.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ZoomPluginDialogComponent } from 'src/app/dialogs/zoom-plugin.dialog.component';
import ZoomPluginConfiguration from 'src/app/models/zoomPluginConfiguration';
import Plugins from 'src/app/models/plugins';
import { LangageSelectionDialogComponent } from 'src/app/dialogs/language-selection.dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit, OnDestroy {
  readonly maxRecentLanguages = 2;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly DEFAULT_LANGUAGE = 'en-US';
  readonly FREE_TRANSLATION_LANGUAGES = 1;
  readonly ADDITIONAL_TRANSLATION_LANGUAGE_COST_IN_MINUTES = 1;
  readonly DRAFT_EVENT_KEY = 'draft_event';

  isLoading: boolean;
  event: Event = new Event();
  eventUrlDomain: string;
  isNewEvent: boolean;
  hasVocabularyBooks: boolean;
  hasBlockedWords: boolean;
  books: VocabularyBook[];
  bookSelection = new FormControl();
  isUnsaved = true;
  defaultLanguage: Language = new Language();
  logoData: any;
  isDraggedOver: boolean;
  enableFileUploads: boolean;

  private subscriptionManager = new SubscriptionManager();

  translationLanguageSelection = new FormControl({ value: '', disabled: true });
  eventNameFormControl = new FormControl('', [Validators.required]);
  eventUrlFormControl = new FormControl('', [Validators.required]);
  eventForm = new FormGroup({
    name: this.eventNameFormControl,
    url: this.eventUrlFormControl
  });

  constructor(
    public globals: GlobalsService,
    private eventService: EventService,
    private messageService: MessageService,
    private vocabularyBookService: VocabularyBookService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.event = new Event();
    this.event.language = 'en-US'; // Default language
    this.eventUrlDomain = `${window.location.protocol}//${window.location.hostname}/listener`;
    this.event.language = this.DEFAULT_LANGUAGE;
    this.isNewEvent = false;
    this.hasVocabularyBooks = false;
    this.hasBlockedWords = false;
    this.books = [];
    this.isDraggedOver = false;
    this.enableFileUploads = environment.enableFileUploads;
    this.event.createdAt = new Date();
  }

  async ngOnInit() {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));

    try {
      this.globals.setIsLoading(true);

      // Check if view is for an existing or new event
      const eventId = this.route.snapshot.paramMap.get('id');
      this.isNewEvent = eventId == null;

      // Load draft event
      if (this.isNewEvent) {
        await this.checkDraftEventAsync();
      }

      // Load existing event
      if (!this.isNewEvent) {
        this.event = await this.eventService.getEventAsync(eventId);

        // Set blocked words
        this.event.blockedWords = this.event.blockedWords || [];
        this.hasBlockedWords = this.event.blockedWords.length > 0;
      }

      // Load plugins
      // This initialization is needed, as plugins has introduced recently and some legacy events
      // don't have plugins.
      if (!this.event.plugins) {
        this.event.plugins = new Plugins();
      }

      // Load available vocabulary books
      this.books = await this.vocabularyBookService.getBooksAsync();
      this.getSelectedBooksFromEvent();
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async createEvent() {
    try {
      this.globals.setIsLoading(true);
      this.addSelectedBooksToEvent();

      // Set blocked words
      if (!this.hasBlockedWords) {
        this.event.blockedWords.length = 0;
      }

      await this.checkBookTrainingStatusAsync();
      const event = await this.eventService.createEventAsync(this.event);
      this.isUnsaved = false;
      this.router.navigate(['/event', event.id]);
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async updateEvent() {
    try {
      this.globals.setIsLoading(true);
      this.addSelectedBooksToEvent();

      // Set blocked words
      if (!this.hasBlockedWords) {
        this.event.blockedWords.length = 0;
      }

      await this.checkBookTrainingStatusAsync();
      const event = await this.eventService.changeEventAsync(this.event);
      this.isUnsaved = false;
      this.router.navigate(['/event', event.id]);
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  onNameChanged() {
    if (this.isNewEvent) {
      // Create url out of event name
      this.event.url = this.event.name.toLowerCase().replace(new RegExp('[^a-zA-Z0-9]+', 'g'), '-');
    }
  }

  onUrlChanged() {
    // Remove all forbidden chars
    this.event.url = this.event.url.toLowerCase().replace(new RegExp('[^a-zA-Z0-9]+', 'g'), '-');
  }

  getVocabularyBookStateName(state: VocabularyBookState): string {
    return VocabularyBookStateUtil.toString(state);
  }

  addWord(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    if (value.length === 0) {
      return;
    }

    // Check, if word already exists
    if (this.event.blockedWords.indexOf(value) >= 0) {
      this.messageService.showToast('"' + value + '" already exists in your blocked words.');
      return;
    }

    // Add word
    this.event.blockedWords.push(value);

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeWord(word: string): void {
    const index = this.event.blockedWords.indexOf(word);

    if (index >= 0) {
      this.event.blockedWords.splice(index, 1);
    }
  }

  onFileSelected(event) {
    let imageData: File;

    // Files can be selected in two ways: File picker and drag and drop. Depending on the mehtod,
    // the event propers can be different: Drag and drop returns a FileList whereas the file picker
    // returns and event. We need to handle both  cases here.
    if (event instanceof FileList) {
      // Handle Drag and Drop
      if (event.length > 0) {
        imageData = event[0];
      }
    } else {
      // Handle Click and select image
      if (event.target.files && event.target.files[0]) {
        imageData = event.target.files[0];
      }
    }

    if (imageData) {
      if (imageData.size > 500000) {
        this.messageService.showDialogAsync(
          'Image too large',
          'The selected file exceeds the maximum allowed file size of 500 KB. Please choose a smaller image.',
          'OK'
        );
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(imageData);
      reader.onload = (loadevent: ProgressEvent) => {
        this.event.logoUrl = String(reader.result);
      };
    }
  }

  onDragOver() {
    this.isDraggedOver = true;
  }

  onDragLeave() {
    this.isDraggedOver = false;
  }

  removeLogo() {
    this.event.logoUrl = null;
  }

  openZoomDialog(): void {
    const dialog = this.dialog.open(ZoomPluginDialogComponent, {
      maxWidth: 900,
      data: this.event.plugins.zoom ? this.event.plugins.zoom : new ZoomPluginConfiguration()
    });

    dialog.afterClosed().subscribe(async result => {
      if (result) {
        this.event.plugins.zoom = result.config;
      }
    });
  }

  removeZoom(): void {
    this.event.plugins.zoom = null;
  }

  onSelectedLanguageChanged(languages: Language[]) {
    if (languages.length > 0) {
      this.event.language = languages[0].code;
    }
  }

  onSelectedTranslationLanguagesChanged(languages: Language[]) {
    if (languages.length > 0) {
      this.event.translationLanguages = languages.map(x => x.code);
    }
  }

  private addSelectedBooksToEvent() {
    if (!this.hasVocabularyBooks) {
      this.event.vocabularyBooks = {};
      return;
    }

    if (!this.bookSelection.value) {
      return;
    }

    this.event.vocabularyBooks = {};

    for (const bookId of this.bookSelection.value) {
      const book = this.books.find(x => x.id === bookId);

      // Ensure that there is only one book per language selected
      if (this.event.vocabularyBooks[book.language] !== undefined) {
        throw Error('You can only select one vocabulary book per langage.');
      }

      this.event.vocabularyBooks[book.language] = book.id;
    }
  }

  private getSelectedBooksFromEvent() {
    if (!this.event || !this.event.vocabularyBooks || Object.keys(this.event.vocabularyBooks).length === 0) {
      return;
    }

    const selected: string[] = [];
    for (const key in this.event.vocabularyBooks) {
      if (this.event.vocabularyBooks.hasOwnProperty(key)) {
        const eventId = this.event.vocabularyBooks[key];
        selected.push(eventId);
      }
    }
    this.bookSelection.setValue(selected);
    this.hasVocabularyBooks = true;
  }

  private async checkDraftEventAsync(): Promise<void> {
    const draft = JSON.parse(localStorage.getItem(this.DRAFT_EVENT_KEY));
    if (!draft || JSON.stringify(draft) === JSON.stringify(this.event)) {
      return;
    }

    const result = await this.messageService.showActionDialogAsync(
      'Draft event',
      'We found an unfinished draft event. Do you want to load it?',
      'LOAD DRAFT',
      'DELETE DRAFT'
    );

    if (result) {
      this.event = draft;
    } else {
      localStorage.removeItem(this.DRAFT_EVENT_KEY);
    }
  }

  private async checkBookTrainingStatusAsync() {
    if (!this.bookSelection.value || this.hasVocabularyBooks === false) {
      return;
    }

    // Check, if a selected book's training has not been started yet and offer to start it right
    // from here.
    for (const bookId of this.bookSelection.value) {
      const book = this.books.find(x => x.id === bookId);
      if (book.state === VocabularyBookState.notStarted) {
        const startTraining = await this.messageService.showActionDialogAsync(
          'Vocabulary book ' + book.name + ' not trained yet.',
          'The straining for vocabulary book ' +
            book.name +
            ' has not been started yet. ' +
            'Untrained vocabulary books can not be used. Do you want to start the training now?',
          'Start training',
          'Continue without training'
        );

        if (startTraining) {
          try {
            this.globals.setIsLoading(true);
            await this.vocabularyBookService.startTrainingAsync(book);
          } catch (error) {
            this.messageService.showToast(error.message);
          } finally {
            this.globals.setIsLoading(false);
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    // Draft event
    if (this.isNewEvent) {
      if (this.isUnsaved) {
        localStorage.setItem(this.DRAFT_EVENT_KEY, JSON.stringify(this.event));
      } else {
        localStorage.removeItem(this.DRAFT_EVENT_KEY);
      }
    }

    this.subscriptionManager.unsubscribeAll();
  }
}
