import { Injectable } from '@angular/core';
import { Event } from '../../models/event';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { BaseApiService } from './base-api.service';
import { LocalCache } from '../../helpers/localCache';
import { TranscriptDownloadRequest } from 'src/app/models/transcriptDownloadRequest';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseApiService {
  private cachedEvents: LocalCache<Event>;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super(authService);
    this.cachedEvents = new LocalCache<Event>();
  }

  async getEventsAsync(showDeleted: boolean = false): Promise<Event[]> {
    let events: Event[];

    // Try to return from local cache
    if (this.cachedEvents.hasItems()) {
      events = this.cachedEvents.getItems();
    } else {
      try {
        const response = await this.httpClient
          .get<Event[]>(environment.apiServiceUrl + '/api/event', {
            headers: await this.getHeadersAsync()
          })
          .toPromise();

        // Update cache
        this.cachedEvents.replace(response);

        events = response;
      } catch (error) {
        throw new Error('An error occurred, while trying to load the list of events.');
      }
    }

    if (!showDeleted) {
      return events.filter(event => !event.isDeleted);
    }

    return events;
  }

  async getEventAsync(idOrUrl: string): Promise<Event> {
    // Try to return from local cache
    const cachedEvent = this.cachedEvents.getItem(x => x.id === idOrUrl || x.url === idOrUrl);
    if (cachedEvent) {
      return cachedEvent;
    }

    try {
      const response = await this.httpClient
        .get<Event>(environment.apiServiceUrl + '/api/event/' + idOrUrl, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();
      return response;
    } catch {
      throw new Error('An error occurred, while trying to load the event.');
    }
  }

  async createEventAsync(event: Event): Promise<Event> {
    try {
      // Upload logo
      if (event.logoData) {
        const uploadData = new FormData();
        uploadData.append('file', event.logoData, event.logoData.name);
        const logoUrl = await this.httpClient
          .post<string>(environment.apiServiceUrl + '/api/event/logo', uploadData, {
            headers: await this.getHeadersAsync()
          })
          .toPromise();
        event.logoUrl = logoUrl;
        console.log('Logo uploaded');
      }

      const response = await this.httpClient
        .post<Event>(environment.apiServiceUrl + '/api/event', event, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Add to local cache
      this.cachedEvents.addItem(response);

      return response;
    } catch (error) {
      if (error.status === 409) {
        // Duplicate
        throw new Error('This URL is invalid or already exists. Please choose a different one.');
      } else {
        throw new Error('An error occurred while trying to create a new event: ' + error.error);
      }
    }
  }

  async changeEventAsync(event: Event): Promise<Event> {
    try {
      // Upload logo
      if (event.logoData) {
        const uploadData = new FormData();
        uploadData.append('file', event.logoData, event.logoData.name);
        const logoUrl = await this.httpClient
          .post<string>(environment.apiServiceUrl + '/api/event/logo', uploadData, {
            headers: await this.getHeadersAsync()
          })
          .toPromise();
        event.logoUrl = logoUrl;
        console.log('Logo updated');
      }

      const response = await this.httpClient
        .put<Event>(environment.apiServiceUrl + '/api/event/' + event.id, event, {
          headers: await this.getHeadersAsync()
        })
        .toPromise();

      // Update local cache
      this.cachedEvents.updateItem(x => x.id === event.id, response);

      return response;
    } catch (error) {
      if (error.status === 409) {
        // Duplicate
        throw new Error('This name already exists. Please choose a different one.');
      }
      throw new Error('An error occurred while trying to change the event: ' + error.error);
    }
  }

  async deleteEventAsync(event: Event, softDelete = false): Promise<Event> {
    try {
      if (softDelete) {
        event.isDeleted = true;
        return await this.changeEventAsync(event);
      } else {
        const response = await this.httpClient
          .delete<Event>(environment.apiServiceUrl + '/api/event/' + event.id, {
            headers: await this.getHeadersAsync()
          })
          .toPromise();

        // Update local cache
        this.cachedEvents.deleteItem(x => x.id === event.id);
        return response;
      }
    } catch (error) {
      throw new Error('An error occurred while trying to delete the event: ' + error.error);
    }
  }

  async getTranscriptAsync(eventId: string, request: TranscriptDownloadRequest): Promise<Blob> {
    try {
      const response = await this.httpClient
        .post(environment.apiServiceUrl + '/api/event/' + eventId + '/export', request, {
          headers: await this.getHeadersAsync(),
          responseType: 'blob'
        })
        .toPromise();

      return response;
    } catch (error) {
      throw new Error('An error occurred, while trying to download the transcript.');
    }
  }

  async ValidateEventPasswordAsync(password: string, event: Event): Promise<Boolean> {
    try {
      const response = await this.httpClient
        .post<Boolean>(
          environment.apiServiceUrl + '/api/event/' + event.id + '/validate',
          password,
          { headers: await this.getHeadersAsync() }
        )
        .toPromise();
      return response;
    } catch {
      throw new Error('An error occurred, while trying to validate the event password.');
    }
  }

  buildListenerUrlFor(event: Event): string {
    return window.location.origin.concat('/listener/', event.url);
  }

  buildCorrectorUrlFor(event: Event): string {
    return window.location.origin.concat('/corrector/', event.url);
  }

  buildQrCodeUrlFor(event: Event): string {
    return window.location.origin.concat('/qrcode/', event.url);
  }
}
