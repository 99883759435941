import { Component, OnInit, OnDestroy } from '@angular/core';
import { Event } from 'src/app/models/event';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/api/admin.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { MatDialog } from '@angular/material/dialog';
import { BookedMinutesDialogComponent } from 'src/app/dialogs/booked-minutes.dialog.component';
import { Billing } from 'src/app/models/billing';
import { BillingService } from 'src/app/services/api/billing.service';
import { BookingLog } from 'src/app/models/bookingLog';

@Component({
  selector: 'app-admin-userdetails',
  templateUrl: './admin-userdetails.component.html',
  styleUrls: ['./admin-userdetails.component.scss']
})
export class AdminUserDetailsComponent implements OnInit, OnDestroy {
  user: User = new User();
  events: Event[];
  usedSeconds: number;
  billings: Billing[];
  bookingLogs: BookingLog[];
  isLoading: boolean;

  private subscriptionManager = new SubscriptionManager();

  constructor(
    public globals: GlobalsService,
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private billingService: BillingService,
    private dialog: MatDialog
  ) {
    this.events = [];
    this.usedSeconds = 0;
    this.billings = [new Billing()];
  }

  async ngOnInit() {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));

    try {
      this.globals.setIsLoading(true);
      const userId = this.route.snapshot.paramMap.get('id');

      this.user = await this.adminService.getUserAsync(userId);
      this.events = await this.adminService.getEventsForUserAsync(userId);
      this.billings = await this.adminService.getBillingsForUserAsync(userId);
      this.bookingLogs = await this.adminService.getBookingLogsForUserAsync(userId);

      const vocabularyBooks = await this.adminService.getVocabularyBooksForUserAsync(userId);
      // Get reference names for each billing
      this.billings.forEach(billing => {
        this.billingService.updateBillingReference(billing, this.events, vocabularyBooks);
      });
    } catch (error) {
      if (error.status === 403) {
        // User is not allowed to access. Navigate back to home
        this.messageService.showToast('No access');
        this.router.navigate(['/']);
      } else {
        this.messageService.showToast('An error occurred');
      }
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }

  async showJson(): Promise<void> {
    const json = JSON.stringify(this.user, null, 2);
    await this.messageService.showDialogAsync('JSON', json, 'Close');
  }

  updateBookedMinutes(): void {
    const dialogRef = this.dialog.open(BookedMinutesDialogComponent, {
      data: {
        userId: this.user.userId,
        bookedMinutes: this.user.bookedMinutes
      }
    });

    const subscription = dialogRef.afterClosed().subscribe({
      next: async () => {
        subscription.unsubscribe();
        this.user = await this.adminService.getUserAsync(this.user.userId);
      }
    });
  }
}
