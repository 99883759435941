import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Billing } from 'src/app/models/billing';
import { GlobalsService } from 'src/app/services/globals.service';
import { MessageService } from 'src/app/services/message.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-billing-table',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnChanges {
  displayedColumns: string[] = [
    'date',
    'referenceType',
    'reference',
    'comment',
    'recordingDuration',
    'translationDuration',
    'totalDuration',
    'isSealed'
  ];
  dataSource: any;

  constructor(public globals: GlobalsService, private messageService: MessageService) {
    this.billings = [];
    this.dataSource = new MatTableDataSource(this.billings);
  }

  @Input() billings: Billing[];
  @ViewChild('sortBillings', { static: true }) sortBillings: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  async ngOnChanges() {
    this.billings = _.sortBy(this.billings, billing => billing.date).reverse();
    this.dataSource = new MatTableDataSource(this.billings);
    this.dataSource.sort = this.sortBillings;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async showCommentAsync(message: string): Promise<void> {
    await this.messageService.showDialogAsync('Information', message, 'OK');
  }

  getTotalRecordingSum() {
    return this.dataSource
      .connect()
      .value.map(t => t.durationInSeconds)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalTranslationSum() {
    return this.dataSource
      .connect()
      .value.map(t => t.translationDurationInSeconds)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalDurationSum() {
    return this.dataSource
      .connect()
      .value.map(t => t.totalDurationInSeconds)
      .reduce((acc, value) => acc + value, 0);
  }
}
