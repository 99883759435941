import { Component, OnInit, Inject } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/api/user.service';
import { User } from 'src/app/models/user';
import { GlobalsService } from 'src/app/services/globals.service';
import { EventService } from 'src/app/services/api/event.service';
import { Event } from 'src/app/models/event';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/api/billing.service';
import { Billing } from 'src/app/models/billing';
import { VocabularyBook } from 'src/app/models/vocabularyBook';
import { VocabularyBookService } from 'src/app/services/api/vocabulary-book.service';
import launchContext from 'src/app/models/launchContext';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user: User;
  originalUser: User;
  events: Event[];
  vocabularyBooks: VocabularyBook[];
  usedPercentage: number;
  bookedHours: number;
  usedHours: number;
  isEditingMode: boolean;
  showDetails: boolean;
  hideDetails: boolean; // disable the feature to get more detail information
  canEditProfile: boolean;
  billings: Billing[];
  disableBilling: boolean;

  constructor(
    public globals: GlobalsService,
    private messageService: MessageService,
    private userService: UserService,
    private authService: AuthService,
    private eventService: EventService,
    private vocabularyBookService: VocabularyBookService,
    private billingService: BillingService,
    public dialog: MatDialog
  ) {
    this.user = new User();
    this.isEditingMode = false;
    this.bookedHours = 0;
    this.usedHours = 0;
    this.usedPercentage = 100;
    this.showDetails = false;
    this.events = [];
    this.vocabularyBooks = [];
    this.canEditProfile = !launchContext.isTeamsMode;
    this.hideDetails = launchContext.isTeamsMode;
    this.billings = [];
    this.disableBilling = environment.disableBilling;
  }

  async ngOnInit() {
    try {
      this.globals.setIsLoading(true);
      this.user = await this.userService.getCurrentUserAsync();
      this.originalUser = Object.assign({}, this.user);
      this.events = await this.eventService.getEventsAsync();
      this.vocabularyBooks = await this.vocabularyBookService.getBooksAsync();
      this.bookedHours = Math.round((this.user.bookedMinutes / 60) * 100) / 100;
      this.usedHours = Math.round((this.user.usedSeconds / 3600) * 100) / 100;
      this.usedPercentage = Math.min(100, (this.usedHours / this.bookedHours) * 100);

      // Billings
      this.billings = await this.billingService.getBillingsAsync();
      const eventsForBillings = await this.eventService.getEventsAsync();
      const vocabularyBooksForBillings = await this.vocabularyBookService.getBooksAsync();
      // Get reference names for each billing
      this.billings.forEach(billing => {
        this.billingService.updateBillingReference(billing, eventsForBillings, vocabularyBooksForBillings);
      });
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  edit() {
    this.isEditingMode = true;
  }

  async saveAsync() {
    // If user changed his E-Mail address, we need to inform him, that
    // he will need to confirm his new E-Mail address
    if (this.user.email !== this.originalUser.email) {
      const result = await this.messageService.showActionDialogAsync(
        'Change your E-Mail?',
        'If you change your E-Mail address, this will become your new login and you can not log in with the old E-Mail address anymore. If you change your E-Mail address, we will send you a confirmation mail to the new account.',
        'Change E-Mail',
        'Cancel'
      );

      if (result === true) {
        await this.updateAccountAsync();
      }
    } else {
      await this.updateAccountAsync();
    }
  }

  async updateAccountAsync() {
    try {
      this.globals.setIsLoading(true);
      await this.userService.updateUserAsync(this.user);
      this.originalUser = Object.assign({}, this.user);
      this.isEditingMode = false;
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  cancel() {
    this.isEditingMode = false;
    this.user = Object.assign({}, this.originalUser);
  }

  async changePasswordAsync() {
    const result = await this.messageService.showActionDialogAsync(
      'Change your password?',
      'We will send you an E-Mail to confirm that it was you, who requested the password change. The E-Mail contains a secure link to where change where your password.',
      'Change Password',
      'Cancel'
    );

    if (result === true) {
      try {
        this.authService.resetPasswordAsync(this.originalUser.email).then(() => {
          this.messageService.showToast('We have sent you an E-Mail to change your password.');
        });
      } catch (error) {
        this.messageService.showToast('An error occurred while trying to change your password.');
      }
    }
  }

  setShowDetails(value: boolean) {
    this.showDetails = value;
  }
}
