export class Billing {
  id: string;
  userId: string;
  referenceId: string;
  referenceType: ReferenceType;
  durationInSeconds: number;
  translationDurationInSeconds: number;
  totalDurationInSeconds: number;
  billingReason: string;
  date: Date;
  comment: string;
  isSealed: boolean;

  // Additional frontend only variables
  referenceName: string;
  referenceFound: boolean;
}

export enum ReferenceType {
  Event = 'Event',
  VocabularyBook = 'VocabularyBook'
}
