import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultDialogData } from './defaultDialogData';

@Component({
  selector: 'app-default-dialog',
  templateUrl: 'default.dialog.component.html'
})
export class DefaultDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DefaultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DefaultDialogData
  ) {}
}
